import { MouseEvent, useMemo } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { SortDirection } from '@playq/octopus-common';

import { ITableColumnSort } from '/shared/Table';

import { QueryChipStyled } from './styles';

export interface ISortChipProps {
  sort: ITableColumnSort<string>;
  columnLabel?: string;

  onChange: (event: MouseEvent<HTMLDivElement>, field: string) => void;
  onClear: (field: string) => void;
  formatPreview?: (ord: SortDirection) => JSX.Element;
}

export function SortChip({ sort, columnLabel, onChange, onClear, formatPreview }: ISortChipProps) {
  const Arrow = sort.ord === SortDirection.Ascending ? ArrowUpward : ArrowDownward;

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    onChange(event, sort.field);
  };

  const handleDelete = () => {
    onClear(sort.field);
  };

  const avatar = useMemo(() => {
    if (!formatPreview) {
      return <Arrow fontSize='small' />;
    }
  }, [Arrow, formatPreview]);

  const label = useMemo(() => {
    if (!formatPreview) {
      return columnLabel || sort.field;
    }
    return formatPreview(sort.ord);
  }, [columnLabel, formatPreview, sort.field, sort.ord]);

  return <QueryChipStyled onDelete={handleDelete} onClick={handleClick} avatar={avatar} label={label} />;
}
