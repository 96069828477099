import { useMemo } from 'react';
import { styled } from '@mui/material';

import { Tag, TagsFilter } from '@playq/octopus-common';

import { FilterForm } from '/shared/Table/components/TableHeadCell/styles';
import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { Tags } from '/shared/Tags';

const TagsWrapper = styled('div')`
  min-width: 220px;
  max-width: 600px;

  .MuiInputBase-input {
    min-width: 90px;
  }
`;

export function TagsFilterCell({ filter, onSubmit, onClear, queryTags }: IFilterCellProps) {
  const tagsFilter = filter as TagsFilter;

  const tags = useMemo<Tag[]>(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!tagsFilter) {
      return [];
    }
    return [
      ...tagsFilter.includes,
      ...tagsFilter.excludes.map((t: Tag) => {
        const nTag = new Tag();
        nTag.key = `-${t.key}`;
        nTag.value = t.value;
        return nTag;
      }),
    ];
  }, [tagsFilter]);

  const handleTagsChange = (nextTags: Tag[]) => {
    if (!nextTags.length) {
      onClear();
      return;
    }

    const nFilter = new TagsFilter();
    nFilter.includes = [];
    nFilter.excludes = [];
    nextTags.forEach((t: Tag) => {
      if (t.key.startsWith('-')) {
        // remove '-' prefix from the key
        t.key = t.key.slice(1, t.key.length);
        nFilter.excludes.push(t);
      } else {
        nFilter.includes.push(t);
      }
    });

    onSubmit(nFilter, false);
  };

  return (
    <FilterForm>
      <TagsWrapper>
        <Tags
          tags={tags}
          onChange={handleTagsChange}
          queryTags={queryTags}
          allowExclude={true}
          allowSystem={true}
          variant='standard'
          label={null}
        />
      </TagsWrapper>
    </FilterForm>
  );
}

TagsFilterCell.type = TagsFilter.ClassName;
