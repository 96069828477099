import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Tag, TagsFilter } from '@playq/octopus-common';
import { FilesFilterField, TagPolicy, TagWithCounter, QueryIntelliTagResponse } from '@playq/octopus2-files';
import { OffsetLimit } from '@playq/services-shared';

import { throwGenericFailure } from '/helpers/throwGenericFailure';
import { services2 } from '/api/services2';
import { useMounted } from '/hooks/useMounted';
import { appToolkit } from '/store/toolkits/app';
import { snackbarService } from '/common/snackbarService';

export const useIntelliTags = (tags?: Tag[]) => {
  const appID = useSelector(appToolkit.selectors.appID);
  const isMounted = useMounted();

  const [policies, setPolicies] = useState<TagPolicy[]>([]);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<QueryIntelliTagResponse[]>([]);

  useEffect(() => {
    services2.appAssetsService.policy().then((data) => {
      data.bifold(
        (res) => {
          if (isMounted.current) {
            setPolicies(res.tags);
          }
        },
        (err) => console.error(err)
      );
    });
  }, [isMounted]);

  const otherTags = useMemo(() => {
    const intelliTags = history[history.length - 1];
    if (intelliTags !== undefined) {
      return intelliTags.other.sort((a, b) => b.count - a.count);
    }
    return [];
  }, [history]);

  const getPoliciesWithCounter = useCallback(
    (targetPolicies: TagPolicy[]) => {
      const lastPolicies = history[history.length - 1]?.policy;
      if (lastPolicies !== undefined) {
        return targetPolicies
          .reduce((acc: TagWithCounter[], policy) => {
            const policyWithCounter = lastPolicies.find((p) => p.key === policy.tag);
            if (policyWithCounter) {
              return acc.concat(policyWithCounter);
            }
            return acc;
          }, [])
          .sort((a, b) => b.count - a.count);
      }
      return [];
    },
    [history]
  );

  const requiredTags = useMemo(
    () => getPoliciesWithCounter(policies.filter((p) => p.required)),
    [policies, getPoliciesWithCounter]
  );
  const creationTags = useMemo(
    () => getPoliciesWithCounter(policies.filter((p) => !p.required)),
    [policies, getPoliciesWithCounter]
  );

  const queryIntelliTags = useCallback(() => {
    if (appID) {
      const tagsFilter = new TagsFilter();
      if (tags) {
        tags.forEach((tag) => {
          if (tag.key.startsWith('-')) {
            tagsFilter.excludes.push(
              new Tag({
                key: tag.key.substring(1),
                value: tag.value,
              })
            );
          } else {
            tagsFilter.includes.push(tag);
          }
        });
      }

      setLoading(true);
      services2.appAssetsService
        .queryIntelliTags(appID, new OffsetLimit({ limit: 30, offset: 0 }), {
          [FilesFilterField.Tag]: tagsFilter,
        })
        .then((data) => {
          if (isMounted.current) {
            setLoading(false);
            data.bifold((res) => {
              setHistory((h) => h.concat(res));
            }, throwGenericFailure);
          }
        })
        .catch((e: Error) => {
          setLoading(false);
          console.error(e);
          snackbarService.genericFailure(e);
        });
    }
  }, [appID, isMounted, tags]);

  useEffect(() => {
    queryIntelliTags();
  }, [queryIntelliTags]);

  return {
    policies,
    loading,
    history,
    otherTags,
    requiredTags,
    creationTags,
  } as const;
};
