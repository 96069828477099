import { Tag, AppID } from '@playq/octopus-common';

import { companyId, KnownTags } from '/constants';

const appKeys = [KnownTags.AppKey, `-${KnownTags.AppKey}`, `$${KnownTags.AppKey}`];

export const getAppIdFromTag = (tag: Tag): AppID | undefined => {
  if (appKeys.includes(tag.key) && tag.value) {
    const appID = new AppID();
    if (!Number.isNaN(+tag.value)) {
      appID.id = +tag.value;
      appID.company = companyId;
      return appID;
    }
  }
};
