import { FC } from 'react';
import { styled } from '@mui/material';

interface IError {
  message: string;
}

export interface IErrorProps {
  error: IError | string;
}

const ErrorWrapper = styled('div')`
  font-size: 20px;
  color: red;
`;

export const TableError: FC<IErrorProps> = ({ error }) => {
  const message = typeof error === 'string' ? error : error.message;
  return <ErrorWrapper>{message}</ErrorWrapper>;
};
