import { SyntheticEvent, useEffect, useState } from 'react';
import { Checkbox, TableCell as MatTableCell } from '@mui/material';

import { ITableState } from '/shared/Table';

export interface ITableSelectionCellProps<D> extends ITableState<D> {
  initialSelection?: boolean;
  indeterminate?: boolean;
  onSelect: (val: boolean) => void;
  className: string;
  isSelectionDisabled?: boolean;
  disableSelection?: (item: D) => boolean;
}

export function GenericTableSelectionCell<D>(props: ITableSelectionCellProps<D>) {
  const { initialSelection = false, indeterminate = false, onSelect, className, isSelectionDisabled } = props;

  const [selected, setSelected] = useState(initialSelection);

  useEffect(() => setSelected(initialSelection), [initialSelection]);

  const handleChange = () => {
    const nSelected = !selected;
    setSelected(nSelected);
    onSelect(nSelected);
  };

  const handleStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <MatTableCell className={className} onClick={handleStopPropagation}>
      <Checkbox
        disabled={isSelectionDisabled}
        checked={selected}
        indeterminate={indeterminate && !selected}
        value={selected.toString()}
        color='primary'
        onChange={handleChange}
      />
    </MatTableCell>
  );
}
