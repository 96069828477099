import { ReactNode } from 'react';
import { TableCell as MatTableCell } from '@mui/material';
import cs from 'classnames';

import { ITableState } from '/shared/Table';

export interface ICellProps<D> extends ITableState<D> {
  className?: string;
  children?: ReactNode;
}

export function GenericTableCell<D>({ className, children, classes }: ICellProps<D>) {
  return <MatTableCell className={cs(classes.cell, className)}>{children}</MatTableCell>;
}
