import { ChangeEvent, FC, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Lock as LockIcon, LockOpen as LockOpenIcon } from '@mui/icons-material';

import { useStateChange } from '/common/useStateChange';
import { handleEnterPress } from '/helpers';

import { TextFieldValidator, TextFieldValidatorProps } from './TextFieldValidator';

/**
 * V is a value type
 */
type LockedTextFieldProps<V> = Omit<TextFieldValidatorProps, 'onChange' | 'value' | 'variant'> & {
  value?: V;
  onChange: (value?: V) => void;
  readonly?: boolean;
  transformFieldValue: (value: string) => V | undefined;
};

export function LockedTextField<V>(props: LockedTextFieldProps<V>) {
  const { value, readonly, schema, transformFieldValue, onChange, ...others } = props;
  const [open, setOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState<V | undefined>(value);

  const noChanges = useStateChange({ state: value, initialState: fieldValue });

  useEffect(() => setFieldValue(value), [value]);

  useEffect(() => {
    setOpen(!noChanges);
  }, [noChanges]);

  const handleLockClick = () => setOpen(!open);

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = transformFieldValue(event.target.value);
    setFieldValue(newValue);
  };

  const handleBlur = () => {
    if ((!schema || schema.isValidSync(value)) && fieldValue !== value) {
      onChange(fieldValue);
      setOpen(false);
    }
  };

  const handleKeyPress = handleEnterPress(handleBlur);

  return (
    <TextFieldValidator
      disabled={readonly || !open}
      value={fieldValue === undefined ? '' : fieldValue}
      schema={schema}
      onChange={handleValueChange}
      InputProps={{
        endAdornment: (
          <IconButton disabled={readonly} size='small' onClick={handleLockClick}>
            {open ? <LockOpenIcon /> : <LockIcon />}
          </IconButton>
        ),
        onBlur: handleBlur,
        onKeyPress: handleKeyPress,
      }}
      {...others}
    />
  );
}

function ofType<V = string>() {
  return LockedTextField as FC<LockedTextFieldProps<V>>;
}

LockedTextField.ofType = ofType;
