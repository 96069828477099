import { useState, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, List, ListItem, ListItemText } from '@mui/material';

import { BooleanFilter } from '@playq/octopus-common';

import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 360,
  },
});

const TRUE = 'TRUE';
const FALSE = 'FALSE';
const initialFilterData = [TRUE, FALSE];

export function BooleanFilterCell({ filter, onSubmit, onClear, filterData = initialFilterData }: IFilterCellProps) {
  const classes = useStyles();
  const [value, setValue] = useState<boolean | undefined>((filter as BooleanFilter)?.value);
  const [selected, setSelected] = useState<ReactNode | undefined>();

  const handleSelect = (option: ReactNode) => () => {
    if (option === selected) {
      setSelected(undefined);
      setValue(undefined);
      onClear();
      return;
    }

    setSelected(option);

    const condition = option === filterData[0];
    setValue(condition);

    const booleanFiter = new BooleanFilter();

    booleanFiter.value = condition;
    onSubmit(booleanFiter, false);
  };

  const setCheckedIndex = (): number => {
    if (value === undefined) return -1;
    return value ? 0 : 1;
  };

  return (
    <List className={classes.root}>
      {filterData.map((option, idx) => {
        return (
          <ListItem
            key={`${String(option)}-${idx}`}
            divider={true}
            dense={true}
            button={true}
            onClick={handleSelect(option)}
          >
            <Checkbox checked={idx === setCheckedIndex()} tabIndex={-1} disableRipple={true} />
            <ListItemText primary={option} />
          </ListItem>
        );
      })}
    </List>
  );
}

BooleanFilterCell.type = BooleanFilter.ClassName;
