import { ChangeEvent, useState } from 'react';
import { Clear as ClearIcon } from '@mui/icons-material';

import { TextFilter } from '@playq/octopus-common';

import { FilterClearButton, FilterForm, FilterInput } from '/shared/Table/components/TableHeadCell/styles';
import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { handleEnterPress } from '/helpers';

export function TextFilterCell({ filter, onSubmit, onClear, currentTheme }: IFilterCellProps) {
  const [value, setValue] = useState(() => (filter ? (filter as TextFilter).text : ''));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleEnter = handleEnterPress(() => {
    if (!value) {
      onClear();
      return;
    }

    const nTextFilter = new TextFilter({ text: value });
    onSubmit(nTextFilter, true);
  });

  return (
    <FilterForm>
      <FilterInput
        value={value}
        onChange={handleChange}
        placeholder='Text filter...'
        onKeyPress={handleEnter}
        autoFocus={true}
        $theme={currentTheme}
        endAdornment={
          <FilterClearButton
            onClick={onClear}
            show={!!value}
            aria-label='Clear Filter'
            title='Clear Filter'
            data-testid='clear-filter'
          >
            <ClearIcon color='disabled' />
          </FilterClearButton>
        }
      />
    </FilterForm>
  );
}

TextFilterCell.type = TextFilter.ClassName;
