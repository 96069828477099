import { MouseEvent, useState, useEffect } from 'react';
import { IconButton, Popover, List, ListItem, Checkbox, ListItemText, Badge } from '@mui/material';
import { ViewColumn as ViewColumnIcon } from '@mui/icons-material';

import { ITableColumn, ITableState, ITableColumnsSelectionProps, useTableStyles } from '/shared/Table';
import { createStorage } from '/storage';

type ITableColumnsSelection<D> = Pick<ITableState<D>, 'columns' | 'hiddenColumns' | 'setHiddenColumns' | 'classes'> & {
  showCheck?: boolean;
};

const storage = createStorage();
const columnsCachePrefix = '#columns_';

export function GenericTableColumnSelection<D>(props: ITableColumnsSelection<D>) {
  const { columns, hiddenColumns, setHiddenColumns, classes, showCheck = false } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (column: ITableColumn<D>) => () => {
    if (isColumnVisible(column)) {
      setHiddenColumns((c) => {
        if (column.label) {
          return c.concat(column.label);
        }

        return c;
      });
    } else {
      setHiddenColumns((c) => c.filter((hiddenColumn) => hiddenColumn !== column.label));
    }
  };

  const isColumnVisible = (column: ITableColumn<D>) =>
    !hiddenColumns.some((hiddenColumn) => hiddenColumn === column.label);

  const isAllColumnsAlwaysVisible = columns.every((c) => c.alwaysVisible);

  const open = Boolean(anchorEl);
  return !isAllColumnsAlwaysVisible ? (
    <>
      <IconButton
        onClick={handleClick}
        className={classes.toolbarColumnSelection}
        data-testid='columns-selection-btn'
        size='large'
      >
        <Badge invisible={!hiddenColumns.length} color='secondary' variant='dot'>
          <ViewColumnIcon />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {columns.map((column) =>
            !column.alwaysVisible ? (
              <ListItem key={column.label} dense={true} button={true} onClick={handleToggle(column)}>
                <Checkbox
                  className={classes.toolbarColumnSelectionCheckbox}
                  checked={showCheck ? !isColumnVisible(column) : isColumnVisible(column)}
                  disableRipple={true}
                  tabIndex={-1}
                />
                <ListItemText primary={column.label} />
              </ListItem>
            ) : null
          )}
        </List>
      </Popover>
    </>
  ) : null;
}

export function CollectionTableColumnSelectionDetached<D>(props: ITableColumnsSelectionProps<D>) {
  const { setHiddenColumns, hiddenColumns } = props;
  const classes = useTableStyles();

  useEffect(() => {
    const item = storage.get(columnsCachePrefix + window.location.pathname);
    if (item) {
      setHiddenColumns(JSON.parse(item) as string[]);
    }
  }, [setHiddenColumns]);

  useEffect(() => {
    storage.set(columnsCachePrefix + window.location.pathname, JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  return <GenericTableColumnSelection {...props} classes={classes} />;
}
