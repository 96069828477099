import { FC, MouseEvent, memo, useMemo } from 'react';
import { Chip } from '@mui/material';

import { useAppQuery } from '/api';

import { getAppIdFromTag, getStyleProps } from '../utils';

import { ITagChipProps } from './types';
import { useStyles } from './styles';

export const TagChip: FC<ITagChipProps> = memo(({ tag, onClick, ...props }) => {
  const classes = useStyles();

  const appId = useMemo(() => getAppIdFromTag(tag), [tag]);
  const { data: app } = useAppQuery(appId, {
    enabled: appId === undefined,
    retry: false,
    staleTime: Infinity,
  });

  const { label, excluded, mainColor, oppositeColor, variant } = useMemo(() => getStyleProps(tag), [tag]);

  const tagLabel = useMemo(() => (app ? `${label} (${app.name})` : label), [app, label]);

  const handleClick = (e: MouseEvent) => {
    if (onClick) {
      e.stopPropagation();
      onClick(tag);
    }
  };

  const style = {
    '--main-color': mainColor,
    '--opposite-color': oppositeColor,
    textDecoration: excluded ? 'line-through' : 'none',
  };

  return (
    <Chip
      onClick={onClick && handleClick}
      label={decodeURI(tagLabel)}
      clickable={!!onClick}
      classes={classes}
      variant={variant}
      color='primary'
      size='small'
      style={style}
      {...props}
    />
  );
});
