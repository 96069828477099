import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { ThemeProvider } from '/common';
import { ThemeMode } from '/common/models';

export const useStyles = makeStyles((theme: Theme) => {
  const themePalette = theme.palette;
  const mainColor: string = ThemeProvider.getAppropriateStyles(
    themePalette.mode as ThemeMode,
    'var(--main-color)',
    themePalette.text.primary
  );

  return {
    root: {
      fontWeight: 'bold',
    },
    sizeSmall: {
      height: '20px',
    },
    colorPrimary: {
      backgroundColor: 'var(--main-color)',
      color: 'var(--opposite-color)',
    },
    clickableColorPrimary: {
      '&:hover': {
        backgroundColor: 'var(--main-color)',
      },
      '&:focus': {
        backgroundColor: 'var(--main-color)',
      },
    },
    deletableColorPrimary: {
      '&:focus': {
        backgroundColor: 'var(--main-color)',
      },
    },
    outlinedPrimary: {
      backgroundColor: `${themePalette.background.paper} !important`,
      border: `1px solid ${mainColor}`,
      color: mainColor,
    },
    deleteIcon: {
      color: 'var(--opposite-color)',
      '&:hover': {
        color: 'var(--opposite-color)',
        opacity: 1,
      },
    },
    deleteIconOutlinedColorPrimary: {
      color: mainColor,
      opacity: 0.7,
      '&:hover': {
        color: mainColor,
        opacity: 1,
      },
    },
  };
});
