import { TextFilterCell } from './TextFilterCell';
import { NumberFilterCell } from './NumberFilterCell';
import { TagsFilterCell } from './TagsFilterCell';
import { TimeFilterCell } from './TimeFilterCell';
import { OptionsFilterCell } from './OptionsFilterCell';
import { PromotionFilterCell } from './PromotionFilterCell';
import { BooleanFilterCell } from './BooleanFilterCell';
import { KeyValueFilterCell } from './KeyValueFilterCell';
import { ContainsFilterCell } from './ContainsFilterCell';

export const filterComponents = [
  TextFilterCell,
  NumberFilterCell,
  TagsFilterCell,
  TimeFilterCell,
  OptionsFilterCell,
  PromotionFilterCell,
  BooleanFilterCell,
  KeyValueFilterCell,
  ContainsFilterCell,
];
