import { useMemo } from 'react';

import { Environment, Filter, PromotionFilter, SpaceID } from '@playq/octopus-common';

import { useSpaceQuery } from '/api';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export function PromotionPreview({ filter, format }: IPreviewComponentProps) {
  const fetchSpaceID = useMemo<SpaceID | undefined>(() => {
    if (filter instanceof PromotionFilter && filter.space) {
      return filter.space;
    }
  }, [filter]);

  const { data: space } = useSpaceQuery(fetchSpaceID);

  if (!(filter instanceof PromotionFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return (
    <>
      {filter.env.map((e) => (e === Environment.Sandbox ? `Sandbox${space ? ` (${space.name})` : ``}` : e)).join(', ')}
    </>
  );
}

PromotionPreview.canPreview = (filter: Filter) => filter instanceof PromotionFilter && filter.env.length > 0;
