import { FC, useEffect, useMemo, useState } from 'react';

import { getTagLabel } from '/helpers';
import { useAppQuery } from '/api';

import { getAppIdFromTag } from '../utils';

import { IOption } from './types';

export const Option: FC<IOption> = ({ tag }) => {
  const [label, setLabel] = useState(getTagLabel(tag));

  const appId = useMemo(() => getAppIdFromTag(tag), [tag]);
  const { data: app } = useAppQuery(appId);

  useEffect(() => {
    const appName = app?.name;
    const tagLabel = getTagLabel(tag);
    if (appName) {
      setLabel(`${tagLabel} (${appName})`);
    } else {
      setLabel(tagLabel);
    }
  }, [tag, app]);

  return <span>{label}</span>;
};
