import { BooleanPreview } from './BooleanPreview';
import { NumberPreview } from './NumberFilter';
import { OptionsPreview } from './OptionsPreview';
import { TagsPreview } from './TagsPreview';
import { TextPreview } from './TextPreview';
import { TimePreview } from './TimePreview';
import { PromotionPreview } from './PromotionPreview';
import { IPreviewComponent } from './IPreviewComponentProps';
import { KeyValuePreview } from './KeyValuePreview';
import { ContainsPreview } from './ContainsPreview';

export const previewComponents: IPreviewComponent[] = [
  BooleanPreview,
  NumberPreview,
  OptionsPreview,
  TagsPreview,
  TextPreview,
  TimePreview,
  PromotionPreview,
  KeyValuePreview,
  ContainsPreview,
];
export * from './IPreviewComponentProps';
