import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CircularProgressStyled } from './Spinner';

export const useFallbackStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    color: theme.palette.grey[500],
  },
}));

export const PageLoaderSpinner = () => {
  const classes = useFallbackStyles();
  return (
    <div className={classes.backdrop}>
      <CircularProgressStyled className={classes.spinner} role='progressbar' />
    </div>
  );
};
