import { TimeFilter, Filter } from '@playq/octopus-common';

import { formatDate } from '/helpers';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export function TimePreview({ filter, format }: IPreviewComponentProps) {
  if (!(filter instanceof TimeFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  const left = formatDate(filter.left, 'DD');
  const right = filter.right ? formatDate(filter.right, 'DD') : undefined;

  return (
    <>
      {filter.expression} -&gt; {right ? `${left} - ${right}` : left}
    </>
  );
}

TimePreview.canPreview = (filter: Filter) => filter instanceof TimeFilter;
