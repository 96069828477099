import { FC, memo } from 'react';
import { Tooltip, Stack } from '@mui/material';
import { CloudCircle, Stars } from '@mui/icons-material';
import { blue, blueGrey, red } from '@mui/material/colors';

import { OtherIcon, RequiredIcon } from '/shared/Tags/PolicyTags/styles';
import { TagsSelect } from '/shared/Tags/PolicyTags/TagsSelect';

import { IntelliTagPickerProps } from './types';

export const IntelliTagsPicker: FC<IntelliTagPickerProps> = memo(
  ({ requiredTags, creationTags, otherTags, handleSelectTag, tags, className, isMeta, classes = {} }) => {
    const isPolicyTagSelected = (key: string) => !!tags?.find((tag) => tag.key === key);

    return (
      <Stack spacing={1} className={className}>
        {(!!requiredTags.length || !!creationTags.length) && (
          <Stack direction='row' spacing={1}>
            <Tooltip title='Suggested' placement='top'>
              <RequiredIcon>
                <Stars />
              </RequiredIcon>
            </Tooltip>
            <Stack direction='row' spacing={1} flexWrap='wrap'>
              {requiredTags.map((tag) => (
                <TagsSelect
                  key={tag.key}
                  onSelect={handleSelectTag}
                  tag={tag}
                  selected={isPolicyTagSelected(tag.key)}
                  mainColor={red.A200}
                  classes={classes}
                />
              ))}
              {creationTags.map((tag) => (
                <TagsSelect
                  key={tag.key}
                  onSelect={handleSelectTag}
                  tag={tag}
                  selected={isPolicyTagSelected(tag.key)}
                  mainColor={blueGrey[500]}
                  classes={classes}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {!!otherTags.length && (
          <Stack direction='row' spacing={1}>
            {!isMeta ? (
              <Tooltip title='Additional Tags' placement='top'>
                <OtherIcon>
                  <CloudCircle />
                </OtherIcon>
              </Tooltip>
            ) : null}
            <Stack direction='row' flexWrap='wrap' justifyContent='flex-start' gap='8px'>
              {otherTags.map((tag) => (
                <TagsSelect
                  key={tag.key}
                  onSelect={handleSelectTag}
                  tag={tag}
                  selected={isPolicyTagSelected(tag.key)}
                  mainColor={isMeta ? blueGrey[500] : blue.A200}
                  canSelectKey={true}
                  classes={classes}
                />
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }
);
