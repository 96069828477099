import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import * as d3 from 'd3';

import { ThemeProvider } from '/common';
import { ThemeMode } from '/common/models';

export const COMMON_TABLE_CELL_PADDING = 14;

const baseSelectionCellStyles = {
  paddingRight: COMMON_TABLE_CELL_PADDING,
  width: 50,
};

const actionIconHeigh = 47;
export const ROW_HOVER_TRANSITION_DELAY = '0.2s';
const STATUS_CELL_MIN_WIDTH = 108;

export const INITIAL_SEARCH_ICON_COLOR = '#9e9e9e';
export const DEFAULT_SELECTED_SEARCH_ICON_COLOR = '#4caf50';

export const useTableStyles = makeStyles((theme: Theme) => {
  const themeTypography = theme.typography;
  const themePalette = theme.palette;
  const themeTransitions = theme.transitions;

  const gradientCellBackground =
    themePalette.mode === 'dark'
      ? `linear-gradient(
    90deg, rgba(48, 48, 48, 0.00) 0%, rgba(48, 48, 48) 10%)`
      : `linear-gradient(
      90deg, rgba(250,251,252, 0.00) 0%, rgba(250,251,252) 10%)`;

  return createStyles({
    tableWrapper: {
      position: 'relative',
    },
    tableContentWrapper: {
      width: '100%',
      overflowX: 'auto',
      paddingBottom: 2,
    },
    table: {
      width: '100%',
    },
    tablePaginationActions: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
    headerCell: {
      position: 'relative',
      textWrap: 'nowrap',
      '&:first-child': {
        paddingLeft: COMMON_TABLE_CELL_PADDING,
      },
      '&:last-child': {
        paddingLeft: COMMON_TABLE_CELL_PADDING,
      },
      '& svg': {
        opacity: 1,
      },
    },

    filterButton: {
      '&:hover': {
        backgroundColor: 'transparent',
      },

      '& svg': {
        verticalAlign: 'middle',
        fontSize: 20,
      },
    },

    filterWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 20,
    },
    filter: {
      maxWidth: 350,
      width: '100%',
    },

    rowSelected: {
      backgroundColor: ((): string => {
        const { r, g, b } = d3.rgb(themePalette.secondary.main);
        return `rgba(${r}, ${g}, ${b}, .1)!important`;
      })(),
    },

    rowHover: {
      cursor: 'pointer',
    },

    cell: {
      paddingLeft: 5,
      paddingRight: 5,
      position: 'relative',

      '&:first-child': {
        paddingLeft: COMMON_TABLE_CELL_PADDING,
      },
      '&:last-child': {
        minWidth: 150,
        paddingRight: COMMON_TABLE_CELL_PADDING,
      },
    },

    idCell: {
      display: 'grid',
    },

    statusCell: {
      minWidth: STATUS_CELL_MIN_WIDTH,
    },
    truncatedCell: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    idWrapper: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    id: {
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    cellMenuActions: {
      width: 50,
    },

    rowLoading: {
      filter: 'blur(5px)',
      pointerEvents: 'none',
    },

    selectionHeadCell: {
      ...baseSelectionCellStyles,
    },
    selectionRowCell: {
      ...baseSelectionCellStyles,
    },

    actionsRowCell: {
      padding: 0,
      position: 'absolute',
      height: '-webkit-fill-available',
      zIndex: theme.zIndex.drawer,
      right: 0,
      top: 0,
      backgroundImage: gradientCellBackground,
      display: 'flex',
      alignItems: 'center',
      opacity: 0,
    },

    staticActionsCell: {
      paddingLeft: 5,
      paddingRight: COMMON_TABLE_CELL_PADDING,
      opacity: 0,
    },

    row: {
      position: 'relative',
      '&:hover': {
        boxShadow: `0px calc(${theme.spacing(1)} / 4) calc(${theme.spacing(1)} / 2) ${theme.palette.grey[400]}`,
        transitionDelay: ROW_HOVER_TRANSITION_DELAY,
        '& $actionsRowCell, $staticActionsCell': {
          opacity: 1,
          transitionDelay: ROW_HOVER_TRANSITION_DELAY,
        },
      },
    },

    actionsRowCellContainer: {
      height: 'auto',
    },

    actionsRowCellSpinner: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: actionIconHeigh,
    },

    actionsRowCellControls: {
      display: 'flex',
      justifyContent: 'flex-end',
      textAlign: 'right',
      transition: themeTransitions.create('opacity', {
        duration: themeTransitions.duration.leavingScreen,
        easing: themeTransitions.easing.sharp,
      }),

      '&.hidden': {
        opacity: 0,
        pointerEvents: 'none',
      },
    },

    actionsHeadCell: {
      paddingLeft: COMMON_TABLE_CELL_PADDING,
      textAlign: 'center',
    },

    toolbarTitle: {
      display: 'flex',
      flexGrow: 1,
    },
    toolbarAnyContainer: {
      justifyContent: 'center',
      marginRight: 10,
      verticalAlign: 'middle',
    },
    toolbarAnyWrapper: {
      display: 'flex',
    },
    toolbarAnyInput: {
      width: 250,
      transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:focus': {
        width: 350,
      },
      '&:autofill, &:autofill:hover, &:autofill:focus, &:autofill:active': {
        transition: 'background-color 5000s ease-in-out 0s',
        webkitTextFillColor: '#fff !important',
      },
    },
    toolbarSpinner: {
      marginRight: 10,
    },
    toolbarAnyInputEndAdornment: {
      marginRight: 10,

      '&:hover > svg': {
        cursor: 'pointer',
        color: themePalette.error.main,
      },
    },
    toolbarColumnSelection: {},
    toolbarColumnSelectionCheckbox: {
      marginRight: '8px',
      padding: 0,
    },
    skeletonCell: {
      paddingTop: `${parseInt(theme.spacing(3)) + themeTypography.fontSize / 2}px`,
      paddingBottom: `${parseInt(theme.spacing(3)) + themeTypography.fontSize / 2}px`,

      '&:after': {
        position: 'absolute',
        left: 0,
        top: '35%',
        content: "''",
        height: '30%',
        width: '80%',
        margin: '0 auto',
        background: `${ThemeProvider.getAppropriateStyles(
          themePalette.mode as ThemeMode,
          'linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%)',
          'linear-gradient(to right, #666666 20%, #4d4d4d 50%, #666666 80%)'
        )}`,
        'background-size': '500px 100px',
        'animation-name': 'moving-gradient',
        'animation-duration': '1s',
        'animation-iteration-count': 'infinite',
        'animation-timing-function': 'linear',
        'animation-fill-mode': 'forwards',
        '&:nth-of-type(even)': {
          backgroundColor: '#f4f8fb',
        },
      },
    },
    skeletonCellSmall: {
      paddingTop: `${parseInt(theme.spacing(2)) + themeTypography.fontSize / 2}px`,
      paddingBottom: `${parseInt(theme.spacing(2)) + themeTypography.fontSize / 2}px`,
    },
    displayNone: { display: 'none' },
    disabledMasterSelectionWrapper: { visibility: 'hidden' },
  });
});
