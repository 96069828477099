import { Children, FC, HTMLAttributes, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Box, CircularProgress, TextField, TextFieldProps as TextFieldPropsType } from '@mui/material';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { Filter, TextFilter } from '@playq/octopus-common';
import { UsersFilterField, UsersSort } from '@playq/octopus2-admin';
import { OffsetLimit } from '@playq/services-shared';

import { useDebounce } from '/hooks';
import { useUsersQuery } from '/api/hooks/corp/usersService';
import { IQuery } from '/common/models';

type UserPickerProps = {
  name: string;
  onChange: (name: string) => void;
  TextFieldProps?: TextFieldPropsType;
  hideAccessError?: boolean;
};

const USERS_LIMIT = 10;
const OPTION_HEIGHT = 42;

const initialIterator = new OffsetLimit({ offset: 0, limit: USERS_LIMIT });

const initialQuery: IQuery<UsersFilterField, UsersSort> = {
  iterator: initialIterator,
  sortBy: [],
  filterBy: {},
};

const ListboxComponent = (shouldShowVirtuoso: boolean, onEndReached: () => void) =>
  forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>((props, ref) => {
    const { children, ...restProps } = props;
    const listRef = useRef<VirtuosoHandle>(null);

    const itemData = useMemo(() => Children.toArray(children), [children]);

    const height = itemData.length * OPTION_HEIGHT;
    const maxHeight = 200;

    return (
      <Box ref={ref} sx={{ height, maxHeight }} {...restProps}>
        {shouldShowVirtuoso ? (
          <Virtuoso
            ref={listRef}
            endReached={onEndReached}
            totalCount={itemData.length}
            itemContent={(index) => itemData[index]}
            initialTopMostItemIndex={itemData.length - USERS_LIMIT}
          />
        ) : (
          children
        )}
      </Box>
    );
  });

export const UserPicker: FC<UserPickerProps> = ({ name, onChange, hideAccessError, TextFieldProps = {} }) => {
  const [inputValue, setInputValue] = useState(name);
  const [options, setOptions] = useState<string[]>([]);
  const [query, setQuery] = useState<IQuery<UsersFilterField, UsersSort>>(initialQuery);

  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      iterator: initialIterator,
      filterBy:
        debouncedInputValue.length > 0
          ? {
              [UsersFilterField.Name]: new TextFilter({
                text: debouncedInputValue,
              }),
            }
          : ({} as Record<string, Filter>),
    }));
  }, [debouncedInputValue]);

  const { total, isLoading } = useUsersQuery(query, false, hideAccessError, {
    onSuccess: (data) => {
      const newOptions = (data?.users ?? []).map((user) => user.name);
      setOptions((prevOptions) => Array.from(new Set([...prevOptions, ...newOptions])));
    },
    enablePrefetch: false,
  });

  const handleInputChange = useCallback((_event: unknown, inputVal: string) => {
    setInputValue(inputVal);
    setOptions([]);
  }, []);

  const handleChange = useCallback(
    (_event: unknown, value: string | null) => {
      onChange(value ?? '');
    },
    [onChange]
  );

  const handleLoadMore = useCallback(() => {
    const prevOffset = query.iterator.offset;
    if (prevOffset < total - USERS_LIMIT) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        iterator: new OffsetLimit({ offset: prevOffset + USERS_LIMIT, limit: USERS_LIMIT }),
      }));
    }
  }, [query, total]);

  const shouldShowVirtuoso = options.length < total;

  return (
    <Autocomplete
      value={name ?? ''}
      onChange={handleChange}
      ListboxComponent={ListboxComponent(shouldShowVirtuoso, handleLoadMore)}
      loading={isLoading}
      options={options}
      onInputChange={handleInputChange}
      filterOptions={(x) => x}
      isOptionEqualToValue={(val, option) => val === option}
      autoHighlight={true}
      autoComplete={true}
      includeInputInList={true}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          label='Author Name'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
