import { FC } from 'react';
import { withStyles, WithStyles } from '@mui/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import cs from 'classnames';

import { ICustomComponent, IPlaceholder } from './IPlaceholder';
import { styles } from './styles';

const DefaultWrapper: ICustomComponent = ({ children, className }) => <div className={className}>{children}</div>;

const DefaultIcon: ICustomComponent = ({ className }) => <SearchIcon className={className} />;

const PlaceholderComponent: FC<IPlaceholder & WithStyles<typeof styles>> = ({
  text = 'No data to display',
  icon: Icon = DefaultIcon,
  wrapper: Wrapper = DefaultWrapper,
  actionButton: ActionButton,
  component: Component,
  classes,
  className,
}) => {
  return Component ? (
    <Component className={className} classes={classes} />
  ) : (
    <Wrapper className={cs(className, classes.wrapper)} classes={classes}>
      <Icon className={classes.icon} classes={classes} />
      <span className={classes.text}>{text}</span>
      {ActionButton && <ActionButton className={classes.actionButton} classes={classes} />}
    </Wrapper>
  );
};

export const Placeholder = withStyles(styles)(PlaceholderComponent);
