import { ChangeEvent, useMemo, useState } from 'react';
import { Clear as ClearIcon } from '@mui/icons-material';
import { FormGroup } from '@mui/material';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';

import { FilterExpression, FilterExpressionHelpers, NumberFilter } from '@playq/octopus-common';

import { FilterClearButton, FilterForm, FilterInput } from '/shared/Table/components/TableHeadCell/styles';
import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { FilterExpressionsSelect } from '/shared/Table/components/TableHeadCell/FilterExpressionsSelect';
import { handleEnterPress } from '/helpers';

interface INumberFilter {
  left: number;
  right: number;
}

const useClasses = makeStyles({
  formGroup: {
    marginTop: 10,
  },
});

export function NumberFilterCell(props: IFilterCellProps) {
  const { filter, CustomFilterInput, onSubmit, onClear, currentTheme } = props;
  const [expression, setExpression] = useState(() => {
    if (!filter) {
      return FilterExpressionHelpers.all[0];
    }

    return (filter as NumberFilter).expression;
  });
  const isRight = useMemo(
    () => expression === FilterExpression.OutOfRange || expression === FilterExpression.InRange,
    [expression]
  );

  const [value, setValue] = useState<INumberFilter>(() => {
    if (!filter) {
      return { left: NaN, right: NaN };
    }

    const numberFilter = filter as NumberFilter;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return { left: numberFilter.left, right: numberFilter.right || NaN };
  });
  const classes = useClasses();

  const leftValue = useMemo(() => (_.isNil(value.left) || _.isNaN(value.left) ? 0 : value.left), [value.left]);
  const rightValue = useMemo(() => (_.isNil(value.right) || _.isNaN(value.right) ? 0 : value.right), [value.right]);

  const customFilter = useMemo(() => {
    const nFilter = new NumberFilter();
    nFilter.expression = expression;
    nFilter.left = leftValue;
    nFilter.right = isRight ? rightValue : undefined;

    return nFilter;
  }, [expression, leftValue, rightValue, isRight]);

  const handleChange = (dir: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const nValue = {
      ...value,
      [dir]: parseInt(e.target.value, 10),
    };
    setValue(nValue);
  };

  const handleEnter = handleEnterPress(() => {
    if (_.isNaN(value.left || (isRight && _.isNaN(value.right)))) {
      onClear();
      return;
    }

    const nNumberFilter = new NumberFilter();
    nNumberFilter.left = value.left;
    nNumberFilter.expression = expression;

    if (value.right) {
      nNumberFilter.right = value.right;
    }

    onSubmit(nNumberFilter, true);
  });

  const handleChangeCustom = (field: 'left' | 'right') => (v: unknown) => {
    setValue((prev) => ({ ...prev, [field]: v as number }));
  };

  return (
    <FilterForm>
      <FilterExpressionsSelect initialExp={expression} onChange={setExpression} />

      <FormGroup className={classes.formGroup}>
        {CustomFilterInput !== undefined ? (
          <CustomFilterInput {...props} filter={customFilter} value={leftValue} onChange={handleChangeCustom('left')} />
        ) : (
          <FilterInput
            value={leftValue || ''}
            onChange={handleChange('left')}
            onKeyPress={handleEnter}
            placeholder={isRight ? 'Left number...' : 'Filter...'}
            type='number'
            autoFocus={true}
            $theme={currentTheme}
            endAdornment={
              !_.isNaN(value.left) && (
                <FilterClearButton
                  onClick={onClear}
                  show={true}
                  aria-label='Clear Filter'
                  title='Clear Filter'
                  data-testid='clear-filter'
                >
                  <ClearIcon color='disabled' />
                </FilterClearButton>
              )
            }
          />
        )}
      </FormGroup>

      {isRight && (
        <FormGroup className={classes.formGroup}>
          {CustomFilterInput !== undefined ? (
            <CustomFilterInput
              {...props}
              filter={customFilter}
              value={rightValue}
              onChange={handleChangeCustom('right')}
            />
          ) : (
            <FilterInput
              value={rightValue || ''}
              onChange={handleChange('right')}
              onKeyPress={handleEnter}
              placeholder='Right number...'
              type='number'
              $theme={currentTheme}
              endAdornment={
                !_.isNaN(value.right) && (
                  <FilterClearButton
                    onClick={onClear}
                    show={true}
                    aria-label='Clear Filter'
                    title='Clear Filter'
                    data-testid='clear-filter'
                  >
                    <ClearIcon color='disabled' />
                  </FilterClearButton>
                )
              }
            />
          )}
        </FormGroup>
      )}
    </FilterForm>
  );
}

NumberFilterCell.type = NumberFilter.ClassName;
