import { BooleanFilter, Filter } from '@playq/octopus-common';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export function BooleanPreview({ filter, format }: IPreviewComponentProps) {
  if (!(filter instanceof BooleanFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return <>{filter.value.toString()}</>;
}

BooleanPreview.canPreview = (filter: Filter) => filter instanceof BooleanFilter;
