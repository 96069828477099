import { blue, red } from '@mui/material/colors';

import { Tag } from '@playq/octopus-common';

import { getOppositeColor, getTagLabel, isValidColor } from '/helpers';
import { IStyleProps } from '/shared/Tags';

export const getStyleProps = (tag: Tag): IStyleProps => {
  const props: IStyleProps = {
    mainColor: blue.A200,
    label: getTagLabel(tag),
    excluded: false,
    variant: 'filled',
  };

  const isTagHaveColor =
    tag.key.includes('color') &&
    tag.value &&
    isValidColor(tag.value) &&
    tag.value !== 'white' &&
    tag.value !== '#fff' &&
    tag.value !== '#ffffff' &&
    tag.value !== 'rgb(255,255,255)' &&
    !tag.value.includes('rgba(255,255,255');

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (isTagHaveColor) {
    props.mainColor = tag.value;
  }

  if (tag.key.startsWith('-')) {
    props.variant = 'outlined';
    props.excluded = true;
  }

  if (tag.key.startsWith('$') || tag.key.startsWith('-$')) {
    props.mainColor = red.A200;
  }

  props.oppositeColor = props.variant === 'outlined' ? props.mainColor : getOppositeColor(props.mainColor as string);

  return props;
};
