/**
 * @description Storage api
 * @method set - put record with provided key
 * @method get - retrieve record by the key
 * @method remove - delete record by the key
 * @method flush - delete all records by defined prefix
 * @method flushExpired - delete all expired by defined prefix
 */
export interface IStorage {
  set: (key: string, value: string, ttl?: number) => void;
  get: (key: string) => string | null;
  remove: (key: string) => void;
  flush: () => void;
  flushExpired: () => void;
}

export type EachItemCallback = (key: string, value: string | null) => void;

/**
 * @description The device/browser api methods.
 * @method setItem - set new record by key
 * @method getItem - retrieve record by the key
 * @method removeItem - remove record by the key
 * @method foreach - go through all record and call the callback for each record
 */
export interface IStorageMethods {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
  foreach: (fn: EachItemCallback) => void;
}

/**
 * @description
 * @param timeUnit describe time steppers
 * @param ttl is determined in TimeUnits. describe how long value will be valid. default value 7 days in seconds
 * @param prefix describe how data will combine together. default value '#tg'
 * @param warnings if true will show warnings in console
 * @param storage the real device storage api should provided
 */
export interface IStorageDefinition {
  timeUnit: TimeUnit;
  ttl: number;
  prefix: string;
  warnings: boolean;
  storage: IStorageMethods;
}

export enum TimeUnit {
  Milliseconds = 1,
  Seconds = 1000,
  Minute = 60000,
  Hour = 3600000,
  Day = 8.64e7,
}
