import { TableRow as MatTableRow, TableCell as MatTableCell } from '@mui/material';

import { ITableState, IRowData } from '/shared/Table';

export interface ITableDetailsRowProps<D> extends ITableState<D> {
  rowData: IRowData<D>;
}

export function GenericTableDetailsRow<D>({ rowData, details, classes, renderColumnsCount }: ITableDetailsRowProps<D>) {
  if (!details) {
    return null;
  }

  return (
    <MatTableRow className={classes.rowDetails}>
      <MatTableCell colSpan={renderColumnsCount}>{details(rowData.item)}</MatTableCell>
    </MatTableRow>
  );
}
