/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ReactNode, SyntheticEvent, useMemo, useState } from 'react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import cs from 'classnames';

import { TableIconAction } from '/shared/Table/components/TableIconAction';
import { ITableState } from '/shared/Table';
import {
  ITableRowAction,
  RenderRowActionFunction,
  RowActionClickEvent,
  StatusRowActionFunction,
  IRowData,
} from '/shared/Table/interfaces';

export interface ITableRowActionsProps<D> extends ITableState<D> {
  rowData: IRowData<D>;
  className?: string;
}

export interface ITableRowActionsComponentProps<D> {
  actions: ITableRowAction<D>[];
  rowData: IRowData<D>;
  handleClick?: (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => void;
  className?: string;
}

const useClasses = makeStyles({
  root: {
    width: 230,
  },
});

function getPendingValue<D>(rowData: IRowData<D>, pending?: boolean | StatusRowActionFunction<D>): boolean {
  if (typeof pending === 'function') {
    return pending(rowData.item);
  }

  return !!pending;
}

function getHiddenValue<D>(rowData: IRowData<D>, hidden?: boolean | StatusRowActionFunction<D>): boolean {
  if (typeof hidden === 'function') {
    return hidden(rowData.item);
  }

  return !!hidden;
}

function getDisabledValue<D>(rowData: IRowData<D>, disabled?: boolean | StatusRowActionFunction<D>): boolean {
  if (typeof disabled === 'function') {
    return disabled(rowData.item);
  }

  return !!disabled;
}

function getTooltipValue<D>(
  rowData: IRowData<D>,
  tooltip?: string | RenderRowActionFunction<D> | ReactNode
): string | ReactNode {
  if (typeof tooltip === 'function') {
    return tooltip(rowData.item);
  }

  return tooltip;
}

function getLabelValue<D>(
  rowData: IRowData<D>,
  label?: string | RenderRowActionFunction<D> | ReactNode
): string | ReactNode {
  if (typeof label === 'function') {
    return label(rowData.item);
  }

  return label;
}

function TableRowActionsMenu<D>(props: ITableRowActionsComponentProps<D>) {
  const { actions, rowData, className } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useClasses();

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    const htmlElement = event.currentTarget as HTMLElement;
    setAnchorEl(htmlElement);
  };

  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelect = (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => {
    clickEvent(event, rowData.item);
    handleClose(event);
  };

  return (
    <>
      <Tooltip disableFocusListener={true} title={`Open ${actions.length} actions`}>
        <IconButton
          className={className}
          aria-label='More'
          aria-owns='long-menu'
          aria-haspopup='true'
          data-testid='table-row-actions-menu'
          onClick={handleClick}
          size='large'
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.root}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {actions.map(({ label, disabled, icon: Icon, onClick, cy, key }: ITableRowAction<D>, idx: number) => (
          <MenuItem
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            key={key || idx}
            data-testid={cy}
            disabled={getDisabledValue(rowData, disabled)}
            selected={false}
            onClick={handleSelect(onClick)}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <Typography variant='inherit' noWrap={true}>
              {getLabelValue(rowData, label)}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function TableRowActionsList<D>({ actions, rowData }: ITableRowActionsComponentProps<D>) {
  const handleClick = (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => {
    event.stopPropagation();
    clickEvent(event, rowData.item);
  };

  return (
    <>
      {actions.map(({ disabled, tooltip, onClick, key, ...rest }: ITableRowAction<D>, idx: number) => (
        <TableIconAction
          key={key ?? idx}
          {...rest}
          disabled={getDisabledValue(rowData, disabled)}
          tooltip={getTooltipValue(rowData, tooltip)}
          onClick={handleClick(onClick)}
        />
      ))}
    </>
  );
}

export function GenericTableRowActions<D>({ rowData, ...props }: ITableRowActionsProps<D>) {
  const { actions = [], actionsLength, classes, options } = props;

  const someProcessing = useMemo<boolean>(() => {
    if (Array.isArray(actions)) {
      return actions.some((a: ITableRowAction<D>) => getPendingValue(rowData, a.pending));
    }
    return Boolean(actions);
  }, [actions, rowData]);

  const visibleActions = useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.filter((a: ITableRowAction<D>) => !getHiddenValue(rowData, a.hidden));
    }
    return actions;
  }, [actions, rowData]);

  const [plainActions, menuActions] = useMemo(() => {
    if (Array.isArray(visibleActions) && visibleActions.length > actionsLength) {
      return [
        visibleActions.slice(0, actionsLength - 1),
        visibleActions.slice(actionsLength - 1, visibleActions.length),
      ];
    }

    return [visibleActions, []];
  }, [actionsLength, visibleActions]);

  const handleClick = (clickEvent: RowActionClickEvent<D>) => (event: SyntheticEvent) => {
    event.stopPropagation();

    clickEvent(event, rowData.item);
  };

  if (typeof actions === 'function') {
    return <>{actions(rowData.item)}</>;
  }

  if (!(actions instanceof Array)) {
    return <>{actions}</>;
  }

  return (
    <div className={options.withAdditionalColumn ? classes.staticActionsCell : classes.actionsRowCell}>
      {someProcessing && (
        <div className={classes.actionsRowCellSpinner}>
          <CircularProgress />
        </div>
      )}
      <div className={cs(classes.actionsRowCellControls, { hidden: someProcessing })}>
        <TableRowActionsList<D>
          actions={plainActions as ITableRowAction<D>[]}
          handleClick={handleClick}
          rowData={rowData}
        />
        {Boolean(menuActions.length) && (
          <TableRowActionsMenu className={classes.actionsRowCellContainer} actions={menuActions} rowData={rowData} />
        )}
      </div>
    </div>
  );
}
