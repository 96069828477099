import { createContext } from 'react';

import { FCWithChildren } from '/common/models';

import { createStorage } from './createStorage';

export const storage = createStorage();
// clear all expired data
storage.flushExpired();

export const StorageContext = createContext(storage);

export const StorageProvider: FCWithChildren = ({ children }) => {
  return <StorageContext.Provider value={storage}>{children}</StorageContext.Provider>;
};
