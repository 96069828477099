import { EachItemCallback, IStorageMethods } from './types';

export const localStorageMethods: IStorageMethods = {
  setItem: (key: string, value: string) => localStorage.setItem(key, value),
  getItem: (key: string) => localStorage.getItem(key),
  removeItem: (key: string) => localStorage.removeItem(key),
  foreach: (fn: EachItemCallback) => {
    const keys = Object.keys(localStorage);
    keys.forEach((key: string) => {
      if (!key) {
        return;
      }

      const value = localStorage.getItem(key);
      fn(key, value);
    });
  },
};
