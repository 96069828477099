/* eslint-disable @typescript-eslint/no-explicit-any,react/no-array-index-key */
import { ComponentProps, useMemo, useContext, useCallback, SyntheticEvent } from 'react';
import { TableRow as MuiTableRow, Theme, Tooltip } from '@mui/material';

import { ITableColumn, IRowData, ITableState } from '/shared/Table';
import { mergeClasses, clipboardWrite } from '/helpers';
import { ThemeModeContext } from '/common';
import { IThemeModeContext } from '/common/models';
import { snackbarService } from '/common/snackbarService';

export interface ITableRowProps<D> extends ITableState<D>, ComponentProps<any> {
  rowData: IRowData<D>;
  overrideProps?: ComponentProps<any>;
}

export function GenericTableRow<D>({ rowData, overrideProps, ...props }: ITableRowProps<D>) {
  const {
    classes,
    options,
    actions,
    components,
    onSelection,
    columns,
    hiddenColumns,
    details,
    onRowClick,
    onDetailsChange,
    getHiddenClasses,
    getEntityURL,
    disableSelection,
  } = props;

  const { item, detailsOpen, id, selected } = rowData;

  const handleClickIdCell = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const textContent = (e?.target as unknown as { textContent: string | undefined })?.textContent?.trim();

    if (textContent === undefined) {
      return;
    }

    clipboardWrite(textContent);
    snackbarService.info(`${textContent} copied to clipboard!`);
  };

  const getCellValue = useCallback(
    (rData: IRowData<D>, { render, label, customRender, truncatedText }: ITableColumn<D>) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!render) {
        return;
      }
      // hard to declare the type of render for different components
      // @ts-expect-error FIXME: Element implicitly has an 'any' type because type '{}' has no index signature.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const renderCellValue = typeof render === 'function' ? render(rData.item, rData) : rData.item[render];

      const isIdCell = label?.toLowerCase() === 'id';
      const isStatusCell = label?.toLowerCase() === 'status';

      if (truncatedText) {
        return (
          <div className={classes.truncatedCell} title={truncatedText}>
            {renderCellValue}
          </div>
        );
      }

      if (!isIdCell || label === undefined || customRender) {
        // hard to declare the type of render for different components
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return renderCellValue;
      }

      if (isStatusCell) {
        return <div className={classes.statusCell}>{renderCellValue}</div>;
      }

      return (
        <div className={classes.idCell}>
          <div className={classes.idWrapper}>
            <Tooltip placement='top' title='Click to copy ID to clipboard'>
              <span className={classes.id} onClick={handleClickIdCell}>
                {renderCellValue}
              </span>
            </Tooltip>
          </div>
        </div>
      );
    },
    [classes]
  );

  const handleSelection = (isSelected: boolean) => {
    onSelection(rowData, isSelected);
  };

  const handleRowCmdClick = () => {
    if (getEntityURL === undefined) {
      return;
    }

    const url = getEntityURL(item);
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleRowClick = (event: SyntheticEvent) => {
    if (onRowClick) {
      if ((event as SyntheticEvent & { metaKey: boolean }).metaKey && getEntityURL) {
        handleRowCmdClick();
        return;
      }
      onRowClick(event, item);
    }

    if (details) {
      onDetailsChange(rowData, !detailsOpen);
    }
  };

  const isColumnHidden = useCallback(
    (column: ITableColumn<D>) => {
      return hiddenColumns.some((hiddenColumn) => hiddenColumn === column.label);
    },
    [hiddenColumns]
  );

  const { Cell } = components;
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const isActionsExist: boolean = useMemo(() => (actions instanceof Array ? actions.length > 0 : !!actions), [actions]);

  const rowCells = useMemo(() => {
    const columnsToRender = columns.filter((column) => !isColumnHidden(column));
    const lastIdx = columnsToRender.length - 1;

    return columnsToRender.map((column: ITableColumn<D>, cellIdx: number) => {
      const columnClasses = getHiddenClasses(column.hidden) || mergeClasses(classes, column.classes || {});
      const shouldShowActions = !options.withAdditionalColumn && isActionsExist && cellIdx === lastIdx;

      return (
        <Cell key={cellIdx} className={columnClasses.cell} {...props}>
          {getCellValue(rowData, column)}
          {shouldShowActions && (
            <components.RowActions rowData={rowData} className={classes.actionsRowCell} {...props} />
          )}
        </Cell>
      );
    });
  }, [
    columns,
    isColumnHidden,
    getHiddenClasses,
    classes,
    options.withAdditionalColumn,
    isActionsExist,
    Cell,
    props,
    getCellValue,
    rowData,
    components,
  ]);

  const themeModeContext: IThemeModeContext = useContext(ThemeModeContext);
  const theme: Theme = themeModeContext.getCurrentTheme();

  const style = props.options.transparentRow
    ? { background: 'transparent' }
    : id % 2
      ? { background: '#00000005' }
      : { background: theme.palette.action.hover };

  return (
    <MuiTableRow
      style={style}
      hover={!!onRowClick || !!details}
      selected={selected}
      classes={{
        selected: classes.rowSelected,
        root: classes.row,
        hover: classes.rowHover,
      }}
      onClick={handleRowClick}
      {...overrideProps}
    >
      {options.withSelection && (
        <components.SelectionRowCell
          className={classes.selectionRowCell || ''}
          initialSelection={selected}
          isSelectionDisabled={disableSelection?.(item)}
          onSelect={handleSelection}
          {...props}
        />
      )}
      {rowCells}
      {options.withAdditionalColumn && (
        <Cell {...props}>
          <components.RowActions rowData={rowData} {...props} />
        </Cell>
      )}
    </MuiTableRow>
  );
}
