import { FC, MouseEvent, useEffect, useMemo, useState, useContext } from 'react';
import { IconButton, Popover } from '@mui/material';

import { Filter } from '@playq/octopus-common';

import {
  ITableColumn,
  ITableState,
  INITIAL_SEARCH_ICON_COLOR,
  DEFAULT_SELECTED_SEARCH_ICON_COLOR,
} from '/shared/Table';
import { FilterAltIcon } from '/shared/Table/components/TableHeadCell/styles';
import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';
import { IThemeModeContext } from '/common/models';
import { ThemeModeContext } from '/common';

import { filterComponents } from './filterComponents';
import { usePopoverPaperClasses } from './styles';

export interface ITableHeadCellFilterProps<D> extends ITableState<D> {
  column: ITableColumn<D>;
}

export function GenericTableHeadCellFilter<D>(props: ITableHeadCellFilterProps<D>) {
  const popoverPaperClasses = usePopoverPaperClasses();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { onFilterClear, onFilterChange, filterBy, classes, column, queryTags } = props;

  const field = useMemo(() => (column.filterField ? column.filterField.toString() : ''), [column.filterField]);
  const data = useMemo(() => column.filterData, [column.filterData]);
  const type = useMemo(() => column.filterType || '', [column.filterType]);
  const fixedFilter = useMemo(() => column.fixedFilter, [column.fixedFilter]);
  const [value, setValue] = useState<Filter | undefined>(() => filterBy[field]);
  const themeModeContext: IThemeModeContext = useContext(ThemeModeContext);

  const filterByValue = filterBy[field];
  useEffect(() => {
    setValue(filterByValue);
  }, [filterByValue]);

  const handlePopoverClose = (reason: string) => {
    if (reason === 'escapeKeyDown' && column.filterField) {
      onFilterClear(column.filterField);
    }
    setAnchorEl(null);
  };

  const handlePopupButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!fixedFilter) {
      setAnchorEl(event.currentTarget);
    }
    if (!anchorEl) {
      column.onFilterOpen?.();
    }
  };

  const handleFilterClear = () => {
    if (column.filterField) {
      onFilterClear(column.filterField);
      setValue(undefined);
      setAnchorEl(null);
    }
  };

  const handleSubmit = (val: Filter, close = true) => {
    if (column.filterField) {
      onFilterChange(column.filterField, val);
      setValue(val);
      if (close) {
        setAnchorEl(null);
      }
    }
  };

  const FilterComponent: FC<IFilterCellProps> | undefined = useMemo(
    () => filterComponents.find((c) => c.type === type),
    [type]
  );

  if (!field) {
    return null;
  }

  if (!FilterComponent) {
    return null;
  }

  return (
    <div>
      <IconButton
        onClick={handlePopupButtonClick}
        data-testid={`${column.label?.toLowerCase() || 'column'}-filter-button`}
        className={classes.filterButton}
        size='small'
      >
        <FilterAltIcon
          fontSize='small'
          htmlColor={column.searchIconColor ?? (value ? DEFAULT_SELECTED_SEARCH_ICON_COLOR : INITIAL_SEARCH_ICON_COLOR)}
        />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        PaperProps={{
          classes: popoverPaperClasses,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {FilterComponent && (
          <FilterComponent
            filter={value}
            filterData={data}
            currentTheme={themeModeContext.getCurrentTheme()}
            CustomFilterInput={column.CustomFilterInput}
            queryTags={queryTags}
            onSubmit={handleSubmit}
            onClear={handleFilterClear}
            validate={column.validateFilterInput}
          />
        )}
      </Popover>
    </div>
  );
}
