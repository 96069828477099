import { CircularProgress, Stack, styled } from '@mui/material';
import { blue, red } from '@mui/material/colors';

export const DisabledVStack = styled(Stack)`
  &.disabled {
    pointer-events: none;
  }
  flex-grow: 1;
`;

export const IconWrapper = styled('div')`
  min-width: 20px;
  height: 24px;
  cursor: help;
`;

export const RequiredIcon = styled(IconWrapper)`
  svg {
    color: ${red.A400};
  }
`;

export const OtherIcon = styled(IconWrapper)`
  svg {
    color: ${blue.A400};
  }
`;

export const Spinner = styled(CircularProgress)`
  position: absolute;
  right: 9px;
`;
