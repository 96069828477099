import { Provider } from 'react-redux';

import { store } from '/store/store';
import { FCWithChildren } from '/common/models';

import { StorePersist } from './StorePersist';

export const StoreProvider: FCWithChildren = ({ children }) => {
  return (
    <Provider store={store}>
      <StorePersist>{children}</StorePersist>
    </Provider>
  );
};
