import { FC } from 'react';
import { Table as MuiTable } from '@mui/material';

import { useTableState } from '/shared/Table/useTableState';

import { ITableProps } from './interfaces';
import { TableError } from './components/TableError';

function GenericTable<D, S extends string = string, F extends string = string>(props: ITableProps<D, S, F>) {
  const state = useTableState(props);
  const { options, classes, components, error, filterBy, sortBy, size } = state;
  const { withToolbar, withPagination } = options;
  const { Toolbar, Pagination, Head, Body, QueryRow } = components;

  const customQueryRowComponent = props.customQueryRowComponent ?? null;
  const showQueryRow = !!customQueryRowComponent || Object.values(filterBy).length > 0 || sortBy.length > 0;

  return (
    <>
      {error !== undefined && error !== '' ? (
        <TableError error={error} />
      ) : (
        <>
          <div className={classes.tableWrapper}>
            {withToolbar && <Toolbar {...state} />}
            {showQueryRow && <QueryRow {...state} />}
            <div className={classes.tableContentWrapper}>
              <MuiTable className={classes.table} size={size} aria-labelledby='tableTitle'>
                <Head {...state} />
                <Body {...state} />
              </MuiTable>
            </div>
            {withPagination && <Pagination {...state} />}
          </div>
        </>
      )}
    </>
  );
}

GenericTable.ofType = <D, S extends string = string, F extends string = string>() => {
  return GenericTable as FC<ITableProps<D, S, F>>;
};

export { GenericTable };
