import { QueryChangeType } from '/shared/Table/interfaces/ITableQuery';

const pageResetTriggers = [
  QueryChangeType.EntitiesPerPage,
  QueryChangeType.Filter,
  QueryChangeType.FilterClear,
  QueryChangeType.SortClear,
];

export function isPageResetNeeded(change: QueryChangeType): boolean {
  return pageResetTriggers.includes(change);
}
