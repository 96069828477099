import { ITableQuery } from '/shared/Table';

import { getInitialQuery } from './getInitialQuery';
import { getSortMap } from './getSortMap';

export function getPredefinedQuery<S extends string>(initialQuery?: ITableQuery<S>) {
  const query = initialQuery || getInitialQuery<S>();

  return {
    filterBy: query.filterBy,
    sortMap: getSortMap<S>(query.sortBy),
    sortBy: query.sortBy,
    page: query.page,
    rowsPerPage: query.rowsPerPage,
  };
}
