import { ITableQuery } from '/shared/Table/interfaces';
import { entitiesLimit } from '/constants';

export function getInitialQuery<S extends string>(): ITableQuery<S> {
  return {
    filterBy: {},
    sortBy: [],
    rowsPerPage: entitiesLimit,
    page: 0,
  };
}
