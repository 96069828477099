import { TextFilter, Filter } from '@playq/octopus-common';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export function TextPreview({ filter, format }: IPreviewComponentProps) {
  if (!(filter instanceof TextFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return <>{filter.text}</>;
}

TextPreview.canPreview = (filter: Filter) => filter instanceof TextFilter;
