import { useMemo } from 'react';

import { Filter, TagsFilter, Tag } from '@playq/octopus-common';

import { StyledTags } from '/shared/Table/components/QueryRow/styles';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export function TagsPreview({ filter, format }: IPreviewComponentProps) {
  const tags = useMemo(() => {
    if (filter instanceof TagsFilter) {
      const tagsFilter = filter;
      return [
        ...tagsFilter.includes,
        ...tagsFilter.excludes.map((t: Tag) => {
          const nTag = new Tag();
          nTag.key = `-${t.key}`;
          nTag.value = t.value;
          return nTag;
        }),
      ];
    }
    return [];
  }, [filter]);

  if (!(filter instanceof TagsFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return <StyledTags tags={tags} label={null} viewOnly={true} allowExclude={true} />;
}

TagsPreview.canPreview = (filter: Filter) => filter instanceof TagsFilter;
