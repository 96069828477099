import { styled } from '@mui/material';

interface ITextAnimationStyled {
  color: string;
  withBorder?: boolean;
}

export const TextAnimation = styled('span', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'withBorder',
})<ITextAnimationStyled>(({ color, withBorder }) => ({
  animationIterationCount: 'infinite',
  color: 'inherit',
  animationDuration: '2s',
  animationTimingFunction: 'linear',
  animationDelay: '0s',
  animationDirection: 'alternate',
  border: !withBorder ? 'none' : '1.5px solid',
  padding: !withBorder ? 'inherit' : '2px 4px',
  borderRadius: '5px',
  animationName: 'textColorAnimation',

  '@keyframes textColorAnimation': {
    '0%': {
      color: 'inherit',
      borderColor: 'inherit',
    },
    '50%': {
      color: color,
      borderColor: color,
    },
    '100%': {
      color: 'inherit',
      borderColor: 'inherit',
    },
  },
}));
