import { Delete as DeleteIcon, Done, Edit, ErrorOutline as ErrorIcon } from '@mui/icons-material';
import { styled } from '@mui/material';

import { IThemeDependentStyledComponentProps } from '/common/models';

export const DeleteIconStyled = styled(DeleteIcon)`
  &:hover {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const ErrorIconStyled = styled(ErrorIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const EditStyled = styled(Edit)<IThemeDependentStyledComponentProps>`
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const DoneStyled = styled(Done)`
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
