import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Link, List, ListItem, ListItemText, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Environment, PromotionFilter, SpaceID } from '@playq/octopus-common';
import { Space } from '@playq/octopus2-apps';

import { useSpaceQuery } from '/api';
import { activeSpaceToolkit, currentModuleToolkit } from '/store';
import { SpaceSelect } from '/common/Spaces/SpaceSelect';
import { IFilterCellProps } from '/shared/Table/components/TableHeadCell/IFilterCellProps';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: 200,
  },
  space: {
    display: 'block',
    maxWidth: 200,
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    wordBreak: 'break-all',
  },
  disabledLink: {
    color: theme.palette.text.disabled,
    pointerEvents: 'none',
  },
}));

const filterData = [Environment.Live, Environment.Sandbox];

export function PromotionFilterCell({ filter: propsFilter, onSubmit, onClear }: IFilterCellProps) {
  const filter = propsFilter as PromotionFilter | undefined;
  const currentActiveSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const isSpaceLessModule = useSelector(currentModuleToolkit.selectors.isSpaceLessModule);
  const activeSpace = isSpaceLessModule ? undefined : currentActiveSpace;

  const classes = useStyles();

  const [spaceId, setSpaceId] = useState<SpaceID | undefined>(filter?.space);
  const [currentSpace, setCurrentSpace] = useState<Space | undefined>();
  const [spaceSelectOpen, setSpaceSelectOpen] = useState(false);

  const selected = useMemo(() => {
    if (!filter) {
      return new Set<Environment>();
    }

    return new Set<Environment>(filter.env);
  }, [filter]);

  const { isFetching } = useSpaceQuery(spaceId, {
    onSettled: (data) => {
      setCurrentSpace(data);
    },
  });

  const updateFilterBySpace = useCallback(
    (selectedSpace?: Space | undefined) => {
      const promotionFilter = new PromotionFilter(filter?.serialize());
      promotionFilter.space = selectedSpace?.id;
      onSubmit(promotionFilter, true);
    },
    [filter, onSubmit]
  );

  useEffect(() => {
    if (isSpaceLessModule) {
      setCurrentSpace(undefined);
      setSpaceId(undefined);
    }
  }, [currentSpace, isSpaceLessModule]);

  useEffect(() => {
    if (!filter) {
      setSpaceId(activeSpace?.id);
      return;
    }
    setSpaceId(filter.space);
  }, [activeSpace, filter]);

  const handleSpaceSelectOpen = () => {
    if (isSpaceLessModule) {
      return;
    }
    setSpaceSelectOpen(true);
  };

  const handleSpaceSelectClose = () => {
    setSpaceSelectOpen(false);
  };

  const handleSpaceSelect = useCallback(
    (selectedSpace?: Space) => {
      setSpaceSelectOpen(false);

      updateFilterBySpace(selectedSpace);

      setCurrentSpace(selectedSpace);
      setSpaceId(selectedSpace?.id);
    },
    [updateFilterBySpace]
  );

  const handleSelect = (option: Environment) => () => {
    const nSelected = new Set(selected);

    if (nSelected.has(option)) {
      nSelected.delete(option);
    } else {
      nSelected.add(option);
    }
    if (nSelected.size === 0) {
      onClear();
      return;
    }

    const promotionFilter = new PromotionFilter();
    promotionFilter.env = Array.from(nSelected);
    if (promotionFilter.env.includes(Environment.Sandbox)) {
      promotionFilter.space = currentSpace?.id;
    }

    setTimeout(() => {
      onSubmit(promotionFilter, true);
    }, 0);
  };

  return (
    <>
      <List className={classes.root}>
        {filterData.map((option) => (
          <ListItem key={option} divider={true} dense={true} button={true} onClick={handleSelect(option)}>
            <Checkbox
              data-testid={`${option.toLowerCase()}-checkbox`}
              checked={selected.has(option)}
              tabIndex={-1}
              disableRipple={true}
            />
            <ListItemText primary={option} />
          </ListItem>
        ))}
      </List>
      <div className={classes.space}>
        <span className={isSpaceLessModule ? classes.disabledLink : ''}>Space: </span>
        <>
          {isFetching ? (
            <i>fetching...</i>
          ) : (
            <Link className={isSpaceLessModule ? classes.disabledLink : ''} onClick={handleSpaceSelectOpen}>
              {currentSpace?.name || 'Default'}
            </Link>
          )}
        </>
      </div>
      {spaceSelectOpen && (
        <SpaceSelect activeSpaceID={currentSpace?.id} onSelect={handleSpaceSelect} onClose={handleSpaceSelectClose} />
      )}
    </>
  );
}

PromotionFilterCell.type = PromotionFilter.ClassName;
