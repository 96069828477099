import { ReactNode, useMemo, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import {
  Refresh as RefreshIcon,
  Close as CloseIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
  Sort as SortIcon,
  Share as ShareIcon,
} from '@mui/icons-material';

import { tooltipEnterDelay } from '/constants';

import { ITableState } from '../../interfaces';

import { IPreviewComponent, previewComponents } from './previewComponents';
import { SortChip } from './SortChip';
import { FilterChip } from './FilterChip';
import { QueryRowStyled, QueryRowCellStyled, QueryChipsLabelStyled, QueryRowWrapperStyled } from './styles';

export function GenericQueryRow<D>(state: ITableState<D>) {
  const {
    filterBy,
    sortBy,
    onFilterChange,
    columns,
    disableQueryShare,
    customQueryRowComponent,
    onFilterClear,
    onSortChange,
    onSortClear,
    onQueryRefresh,
    onQueryReset,
    onQueryShare,
    onClearAllFilters,
  } = state;

  const [filterIcon, setFilterIcon] = useState<JSX.Element>(<SearchIcon />);

  const filterMouseOver = () => {
    setFilterIcon(<ClearIcon />);
  };

  const filterMouseLeave = () => {
    setFilterIcon(<SearchIcon />);
  };

  const sortChips = useMemo(() => {
    if (!sortBy.length) {
      return [];
    }

    return state.sortBy.map((sort) => {
      const column = columns.find((c) => c.sortField === sort.field);
      const sortKey = sort.field + sort.ord;
      return (
        <SortChip
          key={sortKey}
          sort={sort}
          columnLabel={column?.label}
          onChange={onSortChange}
          onClear={onSortClear}
          formatPreview={column?.formatSortPreview}
        />
      );
    });
  }, [sortBy, state.sortBy, columns, onSortChange, onSortClear]);

  const filterChips = useMemo(() => {
    return Object.keys(filterBy).reduce((acc: ReactNode[], key: string) => {
      const column = columns.find((c) => c.filterField === key);
      const isHasPreview = previewComponents.find((c: IPreviewComponent) => c.canPreview(filterBy[key]));
      if (!isHasPreview) {
        return acc;
      }

      const chip = (
        <FilterChip
          key={key}
          filterKey={key}
          filter={filterBy[key]}
          columnLabel={column?.label}
          filterData={column?.filterData}
          formatPreview={column?.formatFilterPreview}
          CustomFilterInput={column?.CustomFilterInput}
          onChange={column?.fixedFilter ? undefined : onFilterChange}
          onClear={column?.fixedFilter ? undefined : onFilterClear}
          validate={column?.validateFilterInput}
        />
      );
      acc.push(chip);
      return acc;
    }, []);
  }, [columns, filterBy, onFilterChange, onFilterClear]);

  return (
    <QueryRowWrapperStyled>
      <QueryRowStyled>
        {Boolean(sortChips.length) && (
          <QueryRowCellStyled>
            <QueryChipsLabelStyled>
              <Tooltip title='Sort By' enterDelay={tooltipEnterDelay} placement='top'>
                <IconButton size='small'>
                  <SortIcon />
                </IconButton>
              </Tooltip>
            </QueryChipsLabelStyled>
            <div>{sortChips}</div>
          </QueryRowCellStyled>
        )}
        {Boolean(filterChips.length) && (
          <QueryRowCellStyled>
            <QueryChipsLabelStyled className='padded'>
              <Tooltip title='Clear Filter By' enterDelay={tooltipEnterDelay} placement='top'>
                <IconButton
                  onClick={onClearAllFilters}
                  onMouseOver={filterMouseOver}
                  onMouseLeave={filterMouseLeave}
                  size='small'
                >
                  {filterIcon}
                </IconButton>
              </Tooltip>
            </QueryChipsLabelStyled>
            <div>{filterChips}</div>
          </QueryRowCellStyled>
        )}
      </QueryRowStyled>
      <QueryRowStyled>
        {customQueryRowComponent}
        {Boolean(filterChips.length || sortChips.length) && (
          <>
            {!disableQueryShare && (
              <Tooltip title='Share' data-testid='share-button' enterDelay={tooltipEnterDelay} placement='top'>
                <IconButton onClick={onQueryShare} size='large'>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            )}
            {onQueryRefresh !== undefined && (
              <Tooltip title='Refresh' data-testid='refresh-button' enterDelay={tooltipEnterDelay} placement='top'>
                <IconButton onClick={onQueryRefresh} size='large'>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Reset' data-testid='reset-button' enterDelay={tooltipEnterDelay} placement='top'>
              <IconButton onClick={onQueryReset} size='large'>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </QueryRowStyled>
    </QueryRowWrapperStyled>
  );
}
