import { Theme } from '@mui/material';
import { createStyles } from '@mui/styles';

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '50px 0',
      color: theme.palette.text.disabled,
    },
    icon: {
      marginBottom: 20,
      fontSize: 80,
    },
    text: {
      fontSize: 16,
    },
    actionButton: {
      marginTop: '20px',
    },
  });
