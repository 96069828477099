import urltron from 'urltron';

import { ITableQuery } from '/shared/Table/interfaces';
import { history } from '/Router/history';
import { IParsedQuery } from '/common/models';
import { QueryHelpers } from '/helpers';

export const getQueryFromURLSearchParams = <S extends string>(): ITableQuery<S> | undefined => {
  const { href } = window.location;

  if (!href.includes('query=')) {
    return;
  }

  const { search } = history.location;
  const params = search.slice(1);
  const parsedParams = urltron.parse(params) as { [key: string]: unknown };

  const nQuery = parsedParams.query as IParsedQuery<S>;

  if (nQuery === undefined) {
    return;
  }

  return { ...nQuery, filterBy: QueryHelpers.deserializeFilterBy(nQuery.filterBy) } as ITableQuery<S>;
};
