/* eslint-disable react/no-array-index-key */
import { TableCell, TableRow } from '@mui/material';
import cs from 'classnames';

import { ITableState } from '/shared/Table';

export function GenericTableSkeletonRows<D>(props: ITableState<D>) {
  const { renderColumnsCount, rowsPerPage, classes } = props;

  const cols = () => {
    return Array.from(new Array(renderColumnsCount)).map((_, idx: number) => {
      return (
        <TableCell
          classes={{ sizeSmall: classes.skeletonCellSmall }}
          className={cs(classes.cell, classes.skeletonCell)}
          key={idx}
        />
      );
    });
  };

  const rows = () => {
    return Array.from(new Array(rowsPerPage)).map((_, idx: number) => {
      return (
        <TableRow className={classes.row} key={idx}>
          {cols()}
        </TableRow>
      );
    });
  };

  return <>{rows()}</>;
}
