import { FC, memo } from 'react';

import { Tag } from '@playq/octopus-common';

import { Tags } from '/shared/Tags/Tags';

import { IPolicyTags } from './types';
import { IntelliTagsPicker } from './IntelliTagsPicker';
import { DisabledVStack, Spinner } from './styles';

export const PolicyTags: FC<IPolicyTags> = memo((props) => {
  const { tags, requiredTags, creationTags, otherTags, history, hideInput, loading, onChange, ...rest } = props;

  const handleSelectTag = (key: string, value?: string) => {
    if (onChange) {
      const tag = new Tag({ key, value });
      if (tags) {
        const tagIndex = tags.findIndex((t) => t.key === tag.key);
        if (tagIndex === -1) {
          onChange(tags.concat(new Tag({ key, value })));
        } else if (tags[tagIndex].value !== tag.value) {
          onChange(tags.map((t, index) => (index === tagIndex ? tag : t)));
        }
      } else {
        onChange([new Tag({ key, value })]);
      }
    }
  };

  const canRemoveTag = (tag: Tag): boolean => {
    const isPolicyTag = history.some((intelliTags) => intelliTags?.policy.some((policy) => policy.key === tag.key));
    const isCloudTag = history.some((intelliTags) => intelliTags?.other.some((otherTag) => otherTag.key === tag.key));
    return tag.key.startsWith('$') ? !!props.allowSystem || isPolicyTag || isCloudTag : true;
  };

  const tagsProps = {
    tags,
    onChange,
    canRemove: canRemoveTag,
    endAdornment: loading && <Spinner size={18} />,
    label: null,
    ...rest,
  };

  return (
    <DisabledVStack className={rest.disabled ? 'disabled' : ''} sx={{ justifyContent: 'space-between' }} spacing={1}>
      <IntelliTagsPicker
        requiredTags={requiredTags}
        creationTags={creationTags}
        otherTags={otherTags}
        handleSelectTag={handleSelectTag}
        tags={tags}
      />
      {!hideInput ? <Tags {...tagsProps} /> : null}
    </DisabledVStack>
  );
});
