import { ReactNode } from 'react';
import { TableBody } from '@mui/material';

import { IRowData, ITableState } from '/shared/Table';

import { Placeholder } from './Placeholder';

export function GenericTableBody<D>(props: ITableState<D>) {
  const {
    data,
    columns,
    hiddenColumns,
    rows,
    rowsPerPage,
    page,
    details,
    processing,
    placeholder,
    getRowKey,
    renderColumnsCount,
    components,
    options,
  } = props;

  const createRowKey = (item: D, row: IRowData<D>): string | number => {
    if (getRowKey) {
      return getRowKey(item, row.id) ?? row.key;
    }
    return row.key;
  };

  const getRenderRows = (): IRowData<D>[] => {
    if (!options.withPagination || rows.length <= rowsPerPage) {
      return rows;
    }

    return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const renderRows = getRenderRows().reduce((acc: ReactNode[], next: IRowData<D>) => {
    acc.push(<components.Row key={createRowKey(next.item, next)} rowData={next} {...props} />);

    if (next.detailsOpen && details) {
      acc.push(<components.DetailsRow key={next.detailsKey} rowData={next} {...props} />);
    }

    return acc;
  }, []);

  const isAllColumnsHidden = columns.every((column) =>
    hiddenColumns.some((hiddenColumn) => column.label === hiddenColumn)
  );

  return (
    <TableBody>
      {((!data.length && !processing) || isAllColumnsHidden) && (
        <tr>
          <td colSpan={renderColumnsCount}>
            <Placeholder {...placeholder} />
          </td>
        </tr>
      )}

      {!data.length && processing && <components.SkeletonRows {...props} />}

      {!isAllColumnsHidden && renderRows}
    </TableBody>
  );
}
