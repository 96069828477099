import { Chip } from '@mui/material';

import { KeyValueFilter, Filter } from '@playq/octopus-common';

import { IPreviewComponentProps } from './IPreviewComponentProps';

const emptyValueValue = 'All';

const setChipColor = (key: string) => {
  const isRequired = key.startsWith('$');

  return isRequired ? 'secondary' : 'primary';
};

export function KeyValuePreview({ filter, format }: IPreviewComponentProps) {
  if (!(filter instanceof KeyValueFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return (
    <>
      {filter.pairs.map(({ key, value }) => (
        <Chip
          size='small'
          key={key + (value || '')}
          label={`${key}: ${value || emptyValueValue}`}
          color={setChipColor(key)}
        />
      ))}
    </>
  );
}

KeyValuePreview.canPreview = (filter: Filter) => filter instanceof KeyValueFilter;
