import { ChangeEvent, FC, FocusEvent, useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { Schema, ValidateOptions, ValidationError } from 'yup';

import { mergeClasses } from '/helpers';
import { useEnableBodyScroll } from '/common/useEnableBodyScroll';

export type TextFieldValidatorProps = TextFieldProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema?: Schema<any> | null;
  alternativeValueToValidate?: unknown;
  errorMsg?: string;
} & {
  validationOptions?: ValidateOptions;
} & {
  classes?: ITextFieldValidatorClasses;
};

interface ITextFieldValidatorClasses extends Record<string, string | undefined> {
  error?: string;
}

export const useTextFieldValidatorStyles = makeStyles(() => ({
  error: {
    position: 'absolute',
    bottom: '-23px',
  },
}));

export const TextFieldValidator: FC<TextFieldValidatorProps> = ({
  value: textFieldValue,
  schema,
  alternativeValueToValidate,
  validationOptions = {},
  helperText,
  errorMsg,
  classes: propsClasses = {},
  ...props
}) => {
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState<undefined | string>();
  const didCancel = useRef<boolean>(false);
  const innerClasses = useTextFieldValidatorStyles();
  const classes = mergeClasses(innerClasses, propsClasses);

  const { enableBodyScroll, disableBodyScroll } = useEnableBodyScroll(props.type === 'number');

  useEffect(() => {
    return () => {
      didCancel.current = true;
    };
  }, []);

  useEffect(() => {
    setError(errorMsg);
    if (errorMsg) {
      setDirty(true);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (!schema) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const valueToValidate: unknown = textFieldValue !== undefined ? textFieldValue : alternativeValueToValidate || '';

    schema
      .validate(valueToValidate, validationOptions)
      .then(() => {
        if (error && !didCancel.current) {
          setError(errorMsg);
        }
      })
      .catch((err: ValidationError) => {
        if (!didCancel.current) {
          setError(err.errors[0]);
        }
      });
  }, [textFieldValue, schema, alternativeValueToValidate, validationOptions, error, errorMsg]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!dirty) {
      setDirty(true);
    }

    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    disableBodyScroll();
    props.onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    enableBodyScroll();
    props.onBlur?.(e);
  };

  return (
    <TextField
      value={textFieldValue}
      FormHelperTextProps={{
        classes: {
          error: classes.error,
        },
      }}
      variant={'standard'}
      error={!!error && dirty}
      helperText={dirty && error ? error : helperText}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...props}
      onChange={handleChange}
    />
  );
};
