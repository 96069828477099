import { IBaseTableOptions, ITableColumn } from '/shared/Table';

export function getRenderColumnsCount<D, S extends string, F extends string>(
  columns: ITableColumn<D, S, F>[],
  hiddenColumns: string[],
  options: IBaseTableOptions
): number {
  let result = columns.filter(
    (column) => column.alwaysVisible || !hiddenColumns.some((hiddenColumn) => hiddenColumn === column.label)
  ).length;

  if (options.withSelection) {
    result += 1;
  }

  if (options.withAdditionalColumn) {
    result += 1;
  }

  return result;
}
