import { SyntheticEvent, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { UserID, TextFilter } from '@playq/octopus-common';
import { NotificationsFilterField } from '@playq/octopus-notifications';

import { useUserPublic } from '/api/hooks/usersService';
import { uiToolkit, appToolkit, ITimelineState } from '/store';
import { QueryHelpers } from '/helpers/query';
import { IWithClassName } from '/common/models';

interface IUserNameProps extends IWithClassName {
  id: UserID;
  name?: string;
  isModal?: boolean;
  onClose?: () => void;
}

const getWrapperSx = (isModal: IUserNameProps['isModal']) => ({
  '&:hover': isModal
    ? 'none'
    : {
        fontWeight: 500,
      },
});

export const UserName = (props: IUserNameProps) => {
  const { id, name: propsName, className, isModal, onClose } = props;
  const { data } = useUserPublic(id, { enabled: !propsName });

  const app = useSelector(appToolkit.selectors.app);
  const dispatch = useDispatch();
  const setTimelineToolkitState = useCallback(
    (val: Partial<ITimelineState>) => dispatch(uiToolkit.actions.setTimelineState(val)),
    [dispatch]
  );

  const handleUserClick = (name: string | undefined) => (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!name || !app) {
      return;
    }
    const userFilter = new TextFilter();
    userFilter.text = name;
    const filterBy = { [NotificationsFilterField.Author]: userFilter };
    if (!isModal) {
      setTimelineToolkitState({ open: true, searchMode: true, filterBy: QueryHelpers.serializeFilterBy(filterBy) });
    }
    if (onClose) {
      onClose();
    }
  };

  const userName = useMemo(() => propsName ?? data?.name, [propsName, data?.name]);

  return (
    <Box sx={getWrapperSx(isModal)} className={className} onClick={handleUserClick(userName)}>
      {userName ?? id.serialize()}
    </Box>
  );
};
