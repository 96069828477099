import { findTagKeyAndValue } from '/helpers';
import { tagsKeyLengthError } from '/constants';

const MIN_TAG_KEY_LENGTH = 3;

export const validateTagInput = (text: string, setError: (err: string | undefined) => void) => {
  const { key } = findTagKeyAndValue(text.trim());

  if (key.length < MIN_TAG_KEY_LENGTH) {
    setError(tagsKeyLengthError);
    return;
  }
  if (text.includes('%')) {
    setError('Contains unallowed characters');
    return;
  }
  setError(undefined);
};
