import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  textField: {
    '& .MuiInputBase-root': {
      fontSize: '15px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0,0,0,1)',
    },
    '& .MuiChip-root.Mui-disabled': {
      opacity: 1,
    },
  },
  disabledAutocomplete: {
    opacity: '0.7',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  tag: {
    margin: '2px',
  },
});
