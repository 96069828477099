import { IBaseTableOptions } from '/shared/Table';

export function getPredefinedOptions(options?: Partial<IBaseTableOptions>): IBaseTableOptions {
  return {
    withMultipleSort: true,
    withMultipleFilter: true,
    withMultipleDetails: false,
    withSelection: false,
    withPagination: true,
    withAnyFilter: true,
    withToolbar: true,
    withColumnSelection: true,
    transparentRow: false,
    withAdditionalColumn: false,
    ...(options || {}),
  };
}
