import { NumberFilter, Filter } from '@playq/octopus-common';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export function NumberPreview({ filter, format }: IPreviewComponentProps) {
  if (!(filter instanceof NumberFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  const left = filter.left;
  const right = filter.right;

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {filter.expression} -&gt; {right ? `${left} - ${right}` : left}
    </>
  );
}

NumberPreview.canPreview = (filter: Filter) => filter instanceof NumberFilter;
