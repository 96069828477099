import { EachItemCallback, IStorageMethods } from './types';

export const sessionStorageMethods: IStorageMethods = {
  setItem: (key: string, value: string) => sessionStorage.setItem(key, value),
  getItem: (key: string) => sessionStorage.getItem(key),
  removeItem: (key: string) => sessionStorage.removeItem(key),
  foreach: (fn: EachItemCallback) => {
    const keys = Object.keys(sessionStorage);
    keys.forEach((key: string) => {
      if (!key) {
        return;
      }

      const value = sessionStorage.getItem(key);
      fn(key, value);
    });
  },
};
