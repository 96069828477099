import { GenericTablePaginationActions, IBaseTableComponents } from '/shared/Table';
import { GenericTableToolbar } from '/shared/Table/components/TableToolbar';
import { GenericTableToolbarActions } from '/shared/Table/components/TableToolbarActions';
import { GenericTableAnyFilter } from '/shared/Table/components/TableAnyFilter';
import { GenericTableColumnSelection } from '/shared/Table/components/TableColumnSelection';
import { GenericTablePagination } from '/shared/Table/components/TablePagination';
import { GenericTableHead } from '/shared/Table/components/TableHead';
import { GenericTableHeadCell } from '/shared/Table/components/TableHeadCell';
import { GenericTableHeadCellFilter } from '/shared/Table/components/TableHeadCell/TableHeadCellFilter';
import { GenericTableSelectionCell } from '/shared/Table/components/TableSelectionCell';
import { GenericTableBody } from '/shared/Table/components/TableBody';
import { GenericTableRow } from '/shared/Table/components/TableRow';
import { GenericTableSkeletonRows } from '/shared/Table/components/TableSkeletonRows';
import { GenericTableDetailsRow } from '/shared/Table/components/TableDetailsRow';
import { GenericTableRowActions } from '/shared/Table/components/TableRowActions';
import { GenericTableCell } from '/shared/Table/components/TableCell';
import { GenericQueryRow } from '/shared/Table/components/QueryRow/QueryRow';

export function getPredefinedComponents<D>(components?: Partial<IBaseTableComponents<D>>): IBaseTableComponents<D> {
  return {
    Toolbar: GenericTableToolbar,
    ToolbarActions: GenericTableToolbarActions,
    AnyFilter: GenericTableAnyFilter,
    ColumnSelection: GenericTableColumnSelection,
    Pagination: GenericTablePagination,
    PaginationActions: GenericTablePaginationActions,
    QueryRow: GenericQueryRow,
    Head: GenericTableHead,
    HeadCell: GenericTableHeadCell,
    HeadCellFilter: GenericTableHeadCellFilter,
    SelectionHeadCell: GenericTableSelectionCell,
    SelectionRowCell: GenericTableSelectionCell,
    Body: GenericTableBody,
    Row: GenericTableRow,
    SkeletonRows: GenericTableSkeletonRows,
    DetailsRow: GenericTableDetailsRow,
    RowActions: GenericTableRowActions,
    Cell: GenericTableCell,

    ...components,
  };
}
