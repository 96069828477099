export const getClosestRowPerPageOption = (entitiesNumber: number, rowsPerPageOptionsSorted: number[]): number => {
  const optionSearchHelper = (options: number[]): number => {
    if (options.length === 1) {
      const [result] = options;

      return result;
    }

    const pivotIndex: number = Math.ceil(options.length / 2);
    const pivot: number = options[pivotIndex];

    if (entitiesNumber < pivot) {
      return entitiesNumber > options[pivotIndex - 1] ? pivot : optionSearchHelper(options.slice(0, pivotIndex));
    }

    if (entitiesNumber > pivot) {
      return entitiesNumber < options[pivotIndex + 1] ? pivot : optionSearchHelper(options.slice(pivotIndex + 1));
    }

    return pivot;
  };

  return optionSearchHelper(rowsPerPageOptionsSorted);
};
