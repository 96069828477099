import * as React from 'react';
import { FC, FocusEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  TextField,
  Typography,
} from '@mui/material';
import cs from 'classnames';

import { Tag, TagSerialized } from '@playq/octopus-common';

import { createTag, getTagLabel, isSystemTag, isTagsEqual, mergeClasses } from '/helpers';

import { GetTagProps, ITagsProps } from './types';
import { useSuggestedTags } from './data';
import { TagChip } from './TagChip';
import { Option } from './Option';
import { useStyles } from './styles';
import { validateTagInput } from './utils';

const handleFilterOptions = (tags: string[]) => tags;

export const convertTagToString = ({ key, value }: Tag | TagSerialized) => `${key}: ${value ?? ''}`;

export const Tags: FC<ITagsProps> = ({
  tags: tagsProp,
  queryTags,
  onChange,
  onTagClick,
  onBlur,
  canRemove,
  allowExclude,
  allowSystem,
  disableAutocomplete,
  disableClearable,
  disabled,
  readonly,
  iteratorLimit = 10,
  viewOnly,
  label: labelProp = 'Tags',
  variant = 'outlined',
  endAdornment,
  className,
  classes: propsClasses = {},
  placeholder = 'Enter/comma to apply Tags',
}) => {
  const innerClasses = useStyles();
  const classes = mergeClasses(innerClasses, propsClasses);
  const isTagKeySelected = useRef(false);

  const label: string | undefined = useMemo(() => (labelProp === null ? undefined : labelProp), [labelProp]);

  const [open, setOpen] = useState(false);

  const [tags, setTags] = useState<Tag[]>(tagsProp || []);
  const tagsAsString = useMemo(() => tags.map(getTagLabel), [tags]);
  useEffect(() => {
    setTags(tagsProp || []);
  }, [tagsProp]);
  const [inputValue, setInputValue] = useState('');

  const [error, setError] = useState<string>();

  useEffect(() => {
    if (inputValue.length === 0) {
      setError(undefined);
      return;
    }
    validateTagInput(inputValue, setError);
  }, [inputValue]);

  const suggestedOptions = useSuggestedTags({
    hint: inputValue,
    queryTags,
    disabled: disableAutocomplete,
    allowSystem,
    limit: iteratorLimit,
  });

  const handleChange = (_: React.SyntheticEvent, newValue: string[], reason: AutocompleteChangeReason) => {
    const lastItemIndex = newValue.length - 1;

    if (error) {
      setInputValue(newValue[lastItemIndex] ?? '');
      return;
    }
    const isInputWithDots = inputValue.endsWith(':');
    if (newValue[lastItemIndex] === undefined && reason === 'selectOption' && isInputWithDots) {
      newValue[lastItemIndex] = inputValue.split(':')[0] || '';
    }
    let canUpdate = true;
    const newTags = newValue.map(createTag);

    if (reason === 'removeOption') {
      const removedTag = tags.find((tag) => !newTags.find((newTag) => isTagsEqual(tag, newTag)));
      if (removedTag) {
        const canRemoveSystemTag = !allowSystem && isSystemTag(removedTag);
        if (canRemove ? !canRemove(removedTag) : canRemoveSystemTag) {
          canUpdate = false;
        }
      }
    }
    if (reason === 'selectOption') {
      const lastTag = newTags[newTags.length - 1];
      if (!lastTag.value) {
        if (inputValue !== `${lastTag.key}:`) {
          setInputValue(`${lastTag.key}:`);
          isTagKeySelected.current = true;
          canUpdate = false;
        }
      }
    }
    if (canUpdate) {
      setTags(newTags);
      if (onChange) {
        onChange(newTags);
      }
    }
  };
  const handleChangeInput = (_: React.SyntheticEvent, newInputValue: string) => {
    const val = newInputValue.trim();

    const excludeTokenIndex = val.indexOf('-');
    if (!allowExclude && excludeTokenIndex === 0) {
      return;
    }

    const systemTokenIndex = val.indexOf('$');
    if (!allowSystem && (systemTokenIndex === 0 || (val.startsWith('-') && systemTokenIndex === 1))) {
      return;
    }
    setInputValue(newInputValue);
  };

  const isSpecialKeyButton = (key: string) => key === ',' || key === 'Tab';

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.defaultPrevented || error || !(inputValue && isSpecialKeyButton(e.key))) {
      return;
    }
    e.preventDefault();
    const tagIndex = tags.findIndex((t) => t.key === inputValue);
    const newTags =
      tagIndex === -1
        ? tags.concat(
            new Tag({
              key: inputValue,
              value: undefined,
            })
          )
        : tags.filter((_, i) => i !== tagIndex);
    setTags(newTags);
    setInputValue('');
    if (onChange) {
      onChange(newTags);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (isTagKeySelected.current) {
      isTagKeySelected.current = false;
    } else {
      setOpen(false);
    }
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (endAdornment) {
      params.InputProps.endAdornment = endAdornment;
    }

    const handleInputBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onBlur) {
        onBlur();
        // @ts-expect-error Property 'onBlur' does not exist on type 'RenderInputParams'.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (params.onBlur) {
          // @ts-expect-error Property 'onBlur' does not exist on type 'RenderInputParams'.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          params.onBlur(e);
        }
      }
    };

    return (
      <TextField
        className={classes.textField}
        {...params}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={handleInputBlur}
        label={viewOnly ? undefined : label}
        placeholder={placeholder}
        variant={variant}
        fullWidth={true}
      />
    );
  };

  const renderTags = (selectedValues: string[], getTagProps: GetTagProps) =>
    selectedValues.map((value, index) => {
      const tag = createTag(value);
      const tagProps = getTagProps({ index });
      const preventDelete = readonly || (canRemove ? !canRemove(tag) : isSystemTag(tag) && !allowSystem);
      if (preventDelete) {
        tagProps.onDelete = undefined;
      }
      return <TagChip tag={tag} onClick={onTagClick} {...tagProps} key={value} />;
    });

  const renderOption = (
    props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement> & React.LiHTMLAttributes<HTMLLIElement>,
    option: string
  ) => (
    <li {...props}>
      <Option tag={createTag(option)} />
    </li>
  );
  return viewOnly ? (
    <div className={className}>
      {tags.map((tag) => (
        <TagChip tag={tag} className={classes.tag} onClick={onTagClick} key={getTagLabel(tag)} />
      ))}
    </div>
  ) : (
    <>
      <Autocomplete
        options={suggestedOptions}
        value={tagsAsString}
        inputValue={inputValue}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        onInputChange={handleChangeInput}
        onKeyDown={handleKeyDown}
        filterOptions={handleFilterOptions}
        renderInput={renderInput}
        renderTags={renderTags}
        renderOption={renderOption}
        disableClearable={readonly || disableClearable}
        className={cs(className, { [innerClasses.disabledAutocomplete]: disabled })}
        disabled={readonly}
        filterSelectedOptions={true}
        freeSolo={true}
        multiple={true}
        size='small'
      />
      {error && (
        <Typography variant='subtitle1' color='error'>
          {error}
        </Typography>
      )}
    </>
  );
};
