import { TagPolicy, TagWithCounter, QueryIntelliTagResponse } from '@playq/octopus2-files';

import { ITagsProps } from '/shared/Tags';

export interface IPolicyTags extends ITagsProps {
  policies?: TagPolicy[];
  requiredTags: TagWithCounter[];
  creationTags: TagWithCounter[];
  otherTags: TagWithCounter[];
  history: QueryIntelliTagResponse[];
  loading?: boolean;
  hideInput?: boolean;
}
export enum IKeyToSort {
  COUNT = 'count',
  NUMBER = 'number',
  ABC = 'ABC',
  DATE = 'date',
}
export enum IDirectionToSort {
  ASC = 'asc',
  DESC = 'desc',
}
