import { useEffect, useMemo, useState, useRef } from 'react';

import { Tag } from '@playq/octopus-common';
import { OffsetLimit } from '@playq/services-shared';

import { useDebounce } from '/hooks/useDebounce';
import { getTagLabel } from '/helpers';
import { services2 } from '/api/services2';
import { QueryTagsHandler } from '/shared/Tags';

const defaultQueryTags: QueryTagsHandler = (...args) => services2.filesService.queryTags(...args);

type HookProps = {
  hint: string;
  queryTags?: QueryTagsHandler;
  disabled?: boolean;
  limit: number;
  allowSystem?: boolean;
};
export const useSuggestedTags = ({ hint, queryTags, disabled, limit, allowSystem }: HookProps) => {
  const isMounted = useRef(true);

  const debouncedHint = useDebounce(hint, 300);
  const handleQueryTags = queryTags || defaultQueryTags;

  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (debouncedHint.length > 0 && !disabled) {
      handleQueryTags(debouncedHint, new OffsetLimit({ limit, offset: 0 }), !allowSystem).then((data) =>
        data.bifold(
          (res) => {
            if (isMounted.current) {
              setTags(res.tags);
            }
          },
          (err) => {
            console.error(err);
            setTags([]);
          }
        )
      );
    } else {
      setTags([]);
    }
  }, [allowSystem, debouncedHint, disabled, handleQueryTags, limit]);

  return useMemo(() => tags.map(getTagLabel), [tags]);
};
