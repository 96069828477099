import { MouseEvent, ChangeEvent } from 'react';
import { TablePagination as MatTablePagination } from '@mui/material';

import { ITableLabelDisplayedRows, ITableState } from '/shared/Table';

export function GenericTablePagination<D>(props: ITableState<D>) {
  const {
    rowsPerPageOptions,
    total,
    data,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    components,
    labelDisplayedRows = defaultLabelDisplayedRows,
  } = props;

  const currentPage = (total || data.length) === 0 ? 0 : page;

  const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, p: number) => {
    onPageChange(p);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const nRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(nRowsPerPage);
  };
  const PaginationActions = components.PaginationActions;
  function defaultLabelDisplayedRows({ from, to, count }: ITableLabelDisplayedRows): string {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }
  return (
    <MatTablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component='div'
      count={total || data.length}
      rowsPerPage={rowsPerPage}
      page={currentPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={() => <PaginationActions {...props} />}
      labelDisplayedRows={labelDisplayedRows}
    />
  );
}
