import { ReactNode } from 'react';
import * as polished from 'polished';
import { styled, Toolbar as MatToolbar, Typography } from '@mui/material';
import { ToolbarProps } from '@mui/material/Toolbar';

import { ITableState } from '/shared/Table';

type StyledToolbarProps = ToolbarProps & { count: number };

type TableToolbarProps<D> = ITableState<D> & {
  content?: ReactNode;
};

const StyledToolbar = styled(MatToolbar)<StyledToolbarProps>`
  padding-right: ${({ theme }) => theme.spacing()}px;
  margin-bottom: ${({ theme }) => theme.spacing()}px;
  background-color: ${({ count, theme }) =>
    count > 0 ? polished.lighten('0.3', theme.palette.secondary.main) : 'transparent'};
`;

export function GenericTableToolbar<D>(props: TableToolbarProps<D>) {
  const { options, selectedRowsCount, toolbarTitle, components, classes } = props;
  const { withAnyFilter, withColumnSelection } = options;
  const { AnyFilter, ColumnSelection, ToolbarActions } = components;

  return (
    <StyledToolbar count={selectedRowsCount} className={classes.toolbarContainer}>
      <div className={classes.toolbarTitle}>
        <Typography variant='h5'>
          {selectedRowsCount > 0 ? `${selectedRowsCount} row(s) selected` : toolbarTitle || null}
        </Typography>
        {props.content}
      </div>
      <div className={classes.toolbarAnyWrapper}>
        {withAnyFilter && <AnyFilter {...props} />}
        {withColumnSelection && <ColumnSelection {...props} />}
        <ToolbarActions {...props} />
      </div>
    </StyledToolbar>
  );
}
