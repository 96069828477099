import { IconButton, Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import {
  LastPage as LastPageIcon,
  FirstPage as FirstPageIcon,
  KeyboardArrowRight,
  KeyboardArrowLeft,
} from '@mui/icons-material';

import { ITableState } from '../interfaces';
const usePaginationActionsStyles = makeStyles((theme: Theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));
export function GenericTablePaginationActions<D>(props: ITableState<D>) {
  const { total, page, rowsPerPage, onPageChange } = props;
  const classes = usePaginationActionsStyles();
  const theme: Theme = useTheme();

  const lastPage = Math.ceil(total / rowsPerPage) - 1;

  function handleFirstPageButtonClick() {
    onPageChange(0);
  }

  function handleBackButtonClick() {
    onPageChange(page - 1);
  }

  function handleNextButtonClick() {
    onPageChange(page + 1);
  }

  function handleLastPageButtonClick() {
    onPageChange(Math.max(0, lastPage));
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='First Page' size='large'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='Previous Page' size='large'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={total !== -1 && page >= lastPage}
        aria-label='Next Page'
        size='large'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= lastPage} aria-label='Last Page' size='large'>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
