import { OptionsFilter, Filter } from '@playq/octopus-common';

import { IPreviewComponentProps } from './IPreviewComponentProps';

export function OptionsPreview({ filter, format }: IPreviewComponentProps) {
  if (!(filter instanceof OptionsFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return <>{filter.options.map((o: string) => o).join(', ')}</>;
}

OptionsPreview.canPreview = (filter: Filter) => filter instanceof OptionsFilter;
