import { FC } from 'react';
import { CircularProgress, CircularProgressProps, styled } from '@mui/material';

export interface ISpinnerProps extends CircularProgressProps {
  centered?: boolean;
  wrapperClassName?: string;
}

const SpinnerWrapper = styled('div')<{ centered?: boolean }>`
  position: absolute;

  ${({ centered }) =>
    centered && {
      width: '50%',
      height: '50%',
      transform: 'translate(50%, 50%)',
    }};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.grey[500]};
`;

export const Spinner: FC<ISpinnerProps> = ({ centered, wrapperClassName, ...rest }) => (
  <SpinnerWrapper className={wrapperClassName} centered={centered} role='progressbar'>
    <CircularProgressStyled {...rest} />
  </SpinnerWrapper>
);
