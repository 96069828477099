import { Chip, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ContainsFilter, Filter } from '@playq/octopus-common';

import { IPreviewComponentProps } from './IPreviewComponentProps';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    overflowY: 'scroll',
    padding: '2px 0px',
  },
}));

const prefix = 'ID: ';

export function ContainsPreview({ filter, format }: IPreviewComponentProps) {
  const classes = useStyles();

  if (!(filter instanceof ContainsFilter)) {
    return null;
  }

  if (format !== undefined) {
    return format(filter);
  }

  return (
    <div>
      <div className={classes.root}>
        {filter.values.map((value: string) => {
          const shortID = value.slice(0, 5);
          const shortTitle = value.length > 5 ? prefix + shortID + `\u2026` : prefix + shortID;
          return (
            <Tooltip placement='top' title={`ID: ${value}`} key={value}>
              <Chip size='small' label={shortTitle} color='primary' />
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}

ContainsPreview.canPreview = (filter: Filter) => filter instanceof ContainsFilter;
