import { IRowData } from '/shared/Table';

export function getRowData<D>(data: D[]): IRowData<D>[] {
  return data.map((item: D, idx: number) => {
    const key = idx + Math.random();
    const detailsKey = key + Math.random();
    return {
      id: idx,
      selected: false,
      processing: false,
      detailsOpen: false,
      key,
      detailsKey,
      item,
    };
  });
}
